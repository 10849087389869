import React, { useEffect, useState } from "react";
import Header from "../header/header";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import axios from "axios";
import { baseUrl } from "../../context/baseUrl";
import { useNavigate, useParams } from "react-router-dom";

const initialValue = {
  productName: "",
  categoryName: "",
  subcategoryName: "",
  productType: "",
  price: "",
  weight: "",
  length: "",
  width: "",
  height: "",
  commission: "",
  shippingCharge: "",
  quantity: "",
  description: "",
  webPrize: "",
  image1: null,
  image2: null,
  image3: null,
  image4: null,
  image5: null,
};

function EditSell() {
  const { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    alert("ID is not define");
    navigate("/productlist");
  }
  const [currentSteps, setCurrentSteps] = useState("1");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [showPreview, setShowPreview] = useState({
    image1:"",
    image2:"",
    image3:"",
    image4:"",
    image5:"",
  });
  const [formData, setFormData] = useState(initialValue);
  const [typesData, setTypesData] = useState(null);

  //--------------------------------------------------------------------------------------------------------
  // console.log(formData);

  const HandleSubmit = async () => {
    try {
      const res = await axios.put(`${baseUrl}/product/updateproduct/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (res?.status == 200) {
        setFormData(initialValue);
        alert("product update successfully.");
      }else{
        alert("product update failed.")
      }
    } catch (error) {}
  };

  const handleImage = (e, img) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };
  const getTypes = async () => {
    try {
      const res = await axios.get(`${baseUrl}/producttype/getalltype`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTypesData(res?.data?.data);
    } catch (e) {}
  };
  const getCategory = async () => {
    try {
      const res = await axios.get(`${baseUrl}/producttype/getallcategory`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCategory(res?.data?.data);
    } catch (e) {}
  };

  const getSingleProduct = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/product/getaproduct/${id}`);
      if(res?.status==200){
          const data = res?.data?.data;
          console.log(data);
          setFormData({...formData, 
            categoryName:data?.categoryName,
            commission:data?.commission,
            description:data?.description,
            price:data?.price,
            length:data?.dimension?.length,
            height:data?.dimension?.height,
            width:data?.dimension?.width,
            productId:data?.productId,
            productName:data?.productName,
            productType:data?.productType,
            quantity:data?.quantity,
            shippingCharge:data?.shippingCharge,
            subcategoryName:data?.subcategoryName,
            webPrize:data?.webPrize,
            weight:data?.weight,
        })
        setShowPreview({...showPreview, 
            image1:data?.displayImages[0]?.url||"",
            image2:data?.displayImages[1]?.url||"",
            image3:data?.displayImages[2]?.url||"",
            image4:data?.displayImages[3]?.url||"",
            image5:data?.displayImages[4]?.url||"",
        })
        }
    } catch (error) {}
  };
  console.log(showPreview)

  useEffect(() => {
    getCategory();
    getTypes();
    getSingleProduct(id)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeCategory = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategory = category?.find(
      (cat) => cat._id === selectedCategoryId
    );
    console.log(selectedCategory);
    setSubCategory(selectedCategory?.subcategories || []);
    setFormData({
      ...formData,
      categoryName: selectedCategory?.categoryName || "",
    });
  };
  const handleSubCat = (e) => {
    setFormData({ ...formData, subcategoryName: e.target.value || "" });
  };
  const handleTitle = (e) => {
    setFormData({ ...formData, productType: e.target.value || "" });
  };
  function handleOnNext() {
    if (currentSteps === "1") {
      setCurrentSteps("2");
    } else {
      console.log("API call and reset state");
    }
  }
  const steps = [
    {
      id: 1,
      tab: "Step 1",
      title: "Add Information",
      component: (
        <StepOne
          setCurrentSteps={setCurrentSteps}
          currentSteps={currentSteps}
        />
      ),
    },
    {
      id: 2,
      tab: "Step 2",
      title: "Add Detail",
      component: (
        <StepTwo
          setCurrentSteps={setCurrentSteps}
          currentSteps={currentSteps}
        />
      ),
    },
    // {
    //   id: 3,
    //   tab: "Step 3",
    //   title: "User Inforamtion",
    //   component: (
    //     <StepThree
    //       setCurrentSteps={setCurrentSteps}
    //       currentSteps={currentSteps}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <Header />
      <section className="hero-section">
        <div className="sell-box">
          <div className="sell-box-container">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              {steps.map((tab, i) => (
                /*  <button
                  key={i}
                  id={tab.id}
                  disabled={currentTab === `${tab.id}`}
                  onClick={handleTabClick}
                >
                  {tab.tabTitle}
                </button> */
                <li className="nav-item" key={i} role="presentation">
                  <button
                    type="button"
                    className="step-btn"
                    id={tab.id}
                    disabled={currentSteps === `${tab.id}`}
                  >
                    {tab.tab}
                  </button>
                  <p className="nav-link-underline active">{tab.title}</p>
                </li>
              ))}
            </ul>
            <div className="content">
              {/* {steps.map((tab, i) => (
                <div key={i}>
                  {currentSteps === `${tab.id}` && <div>{tab.component}</div>}
                </div>
              ))} */}
              <div>
                {currentSteps === "1" && (
                  <div className="step-one-container">
                    <h6 className="step-one-title">Upload Images</h6>
                    <div className="file-upload">
                      <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg">
                          <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                              {showPreview?.image1 =="" ? (
                                <>
                                  <input
                                    id="img-1"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleImage(e, "image1");
                                    }}
                                  />
                                  <label
                                    htmlFor="img-1"
                                    className="file-plush-btn"
                                  ></label>
                                </>
                              ) : (
                                <>
                                  <div className="preview-img">
                                    <img
                                      alt=""
                                      height="100px"
                                      src={showPreview.image1}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="remove-btn w-50"
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          image1: null,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg">
                          <div className="d-flex flex-column">
                            <div className="d-flex-flex-column">
                              {showPreview?.image2 =="" ? (
                                <>
                                  <input
                                    id="img-2"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleImage(e, "image2");
                                    }}
                                  />
                                  <label
                                    htmlFor="img-2"
                                    className="file-plush-btn"
                                  ></label>
                                </>
                              ) : (
                                <>
                                  <div className="preview-img">
                                    <img
                                      alt=""
                                      height="100px"
                                      src={showPreview.image2}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="remove-btn w-50"
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          image2: null,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg">
                          <div className="d-flex flex-column">
                            <div className="d-flex-flex-column">
                              {showPreview?.image3 =="" ? (
                                <>
                                  <input
                                    id="img-3"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleImage(e, "image3");
                                    }}
                                  />
                                  <label
                                    htmlFor="img-3"
                                    className="file-plush-btn"
                                  ></label>
                                </>
                              ) : (
                                <>
                                  <div className="preview-img">
                                    <img
                                      alt=""
                                      height="100px"
                                      src={showPreview.image3}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="remove-btn w-50"
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          image3: null,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg">
                          <div className="d-flex flex-column">
                            <div className="d-flex-flex-column">
                              { showPreview?.image4 ==""? (
                                <>
                                  <input
                                    id="img-4"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleImage(e, "image4");
                                    }}
                                  />
                                  <label
                                    htmlFor="img-4"
                                    className="file-plush-btn"
                                  ></label>
                                </>
                              ) : (
                                <>
                                  <div className="preview-img">
                                    <img
                                      alt=""
                                      height="100px"
                                      src={showPreview.image4}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="remove-btn w-50"
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          image4: null,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg">
                          <div className="d-flex flex-column">
                            <div className="d-flex-flex-column">
                              { showPreview?.image5 =="" ? (
                                <>
                                  <input
                                    id="img-5"
                                    hidden
                                    type="file"
                                    onChange={(e) => {
                                      handleImage(e, "image5");
                                    }}
                                  />
                                  <label
                                    htmlFor="img-5"
                                    className="file-plush-btn"
                                  ></label>
                                </>
                              ) : (
                                <>
                                  <div className="preview-img">
                                    <img
                                      alt=""
                                      height="100px"
                                      src={showPreview.image5}
                                    />
                                  </div>
                                  <div>
                                    <button
                                      className="remove-btn w-50"
                                      onClick={() =>
                                        setFormData({
                                          ...formData,
                                          image5: null,
                                        })
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="step-one-title">Product Name</h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="productName"
                      value={formData?.productName || ""}
                      onChange={handleChange}
                    />
                    <h6 className="step-one-title">Category</h6>
                    <select
                      onChange={handleChangeCategory}
                      className="step-one-input"
                    >
                      <option value={""}>Select</option>
                      {category &&
                        category.length > 0 &&
                        category.map((element, index) => (
                          <option key={index} value={element?._id}>
                            {element?.categoryName}
                          </option>
                        ))}
                    </select>

                    <h6 className="step-one-title">Sub Category</h6>
                    <select onChange={handleSubCat} className="step-one-input">
                      <option value={""}>Select</option>
                      {subCategory &&
                        subCategory.length > 0 &&
                        subCategory?.map((element, index) => {
                          return (
                            <>
                              <option key={index} value={element}>
                                {element}
                              </option>
                            </>
                          );
                        })}
                    </select>
                    <h6 className="step-one-title">
                      Type * <span>Select suitable category for your ad</span>
                    </h6>
                    <select onChange={handleTitle} className="step-one-input" value={formData?.productType}>
                      <option value={""}>Select</option>
                      {typesData &&
                        typesData.length > 0 &&
                        typesData?.map((element, index) => {
                          return (
                            <>
                              <option key={index} value={element?.productType}>
                                {element?.productType}
                              </option>
                            </>
                          );
                        })}
                    </select>
                    {/* <h6 className="step-one-title">
                    <span>Select suitable category for your ad</span>
                  </h6>
                  <select className="step-one-input">
                    <option value=""></option>
                  </select> */}
                    <div className="bottom-btn d-flex justify-content-end">
                      <button
                        disabled={currentSteps === "1"}
                        className="prev-btn"
                      >
                        PREVIOUS
                      </button>
                      <button onClick={handleOnNext} className="next-btn">
                        NEXT
                      </button>
                    </div>
                  </div>
                )}
                {currentSteps === "2" && (
                  <div className="step-two-container">
                    {/* <h6 className="step-one-title">Type of Ad *</h6>
                  <select className="step-one-input">
                    <option value=""></option>
                  </select> */}
                    {/* <h6 className="step-one-title">Price Type *</h6>
                  <select className="step-one-input">
                    <option value=""></option>
                  </select> */}
                    <h6 className="step-one-title">Price ₹ only *</h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="price"
                      value={formData?.price || ""}
                      onChange={handleChange}
                    />
                    <h6 className="step-one-title">Weight*</h6>
                    <select
                      className="step-one-input"
                      name="weight"
                      onChange={handleChange}
                      value={formData?.weight}
                    >
                      <option value="">Select Weight</option>
                      <option value="< 0.5 KG">0.5 KG</option>
                      <option value="0.5KG - 1 KG">0.5KG - 1 KG</option>
                      <option value="1KG - 1 KG">1KG - 2 KG</option>
                      <option value="2KG - 1 KG">2KG - 3 KG</option>
                      <option value="3KG - 1 KG">3KG - 4 KG</option>
                      <option value="4KG - 1 KG">4KG - 5 KG</option>
                      <option value="5KG - 1 KG">5KG - 6 KG</option>
                      <option value="6KG - 1 KG">6KG - 7 KG</option>
                      <option value="7KG - 1 KG">7KG - 8 KG</option>
                      <option value="8KG - 1 KG">8KG - 9 KG</option>
                      <option value="9KG - 1 KG">9KG - 10 KG</option>
                    </select>
                    <h6 className="step-one-title">Dimension*</h6>
                    <div className="row">
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-center">
                          <h6 className="step-one-title m-0">Length*</h6>
                          <select
                            className="step-one-input m-0 mx-3"
                            style={{ width: "50%" }}
                            name="length"
                            onChange={handleChange}
                            value={formData?.length}
                          >
                            <option value="">Select cms</option>
                            <option value="< 0.5 cms">0.5 cms</option>
                            <option value="0.5cms - 1 cms">
                              0.5cms - 1 cms
                            </option>
                            <option value="1cms - 1 cms">1cms - 2 cms</option>
                            <option value="2cms - 1 cms">2cms - 3 cms</option>
                            <option value="3cms - 1 cms">3cms - 4 cms</option>
                            <option value="4cms - 1 cms">4cms - 5 cms</option>
                            <option value="5cms - 1 cms">5cms - 6 cms</option>
                            <option value="6cms - 1 cms">6cms - 7 cms</option>
                            <option value="7cms - 1 cms">7cms - 8 cms</option>
                            <option value="8cms - 1 cms">8cms - 9 cms</option>
                            <option value="9cms - 1 cms">9cms - 10 cms</option>
                          </select>
                          <h6 className="step-one-title m-0">cm</h6>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-center">
                          <h6 className="step-one-title m-0">Width*</h6>
                          <select
                            className="step-one-input m-0 mx-3"
                            style={{ width: "50%" }}
                            name="width"
                            onChange={handleChange}
                            value={formData?.width}
                          >
                            <option value="">Select cms</option>
                            <option value="< 0.5 cms">0.5 cms</option>
                            <option value="0.5cms - 1 cms">
                              0.5cms - 1 cms
                            </option>
                            <option value="1cms - 1 cms">1cms - 2 cms</option>
                            <option value="2cms - 1 cms">2cms - 3 cms</option>
                            <option value="3cms - 1 cms">3cms - 4 cms</option>
                            <option value="4cms - 1 cms">4cms - 5 cms</option>
                            <option value="5cms - 1 cms">5cms - 6 cms</option>
                            <option value="6cms - 1 cms">6cms - 7 cms</option>
                            <option value="7cms - 1 cms">7cms - 8 cms</option>
                            <option value="8cms - 1 cms">8cms - 9 cms</option>
                            <option value="9cms - 1 cms">9cms - 10 cms</option>
                          </select>
                          <h6 className="step-one-title m-0">cm</h6>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-center">
                          <h6 className="step-one-title m-0">Height*</h6>
                          <select
                            className="step-one-input m-0 mx-3"
                            style={{ width: "50%" }}
                            name="height"
                            onChange={handleChange}
                            value={formData?.height}
                          >
                            <option value="">Select cms</option>
                            <option value="< 0.5 cms">0.5 cms</option>
                            <option value="0.5cms - 1 cms">
                              0.5cms - 1 cms
                            </option>
                            <option value="1cms - 1 cms">1cms - 2 cms</option>
                            <option value="2cms - 1 cms">2cms - 3 cms</option>
                            <option value="3cms - 1 cms">3cms - 4 cms</option>
                            <option value="4cms - 1 cms">4cms - 5 cms</option>
                            <option value="5cms - 1 cms">5cms - 6 cms</option>
                            <option value="6cms - 1 cms">6cms - 7 cms</option>
                            <option value="7cms - 1 cms">7cms - 8 cms</option>
                            <option value="8cms - 1 cms">8cms - 9 cms</option>
                            <option value="9cms - 1 cms">9cms - 10 cms</option>
                          </select>
                          <h6 className="step-one-title m-0">cm</h6>
                        </div>
                      </div>
                    </div>
                    <h6 className="step-one-title mt-4">
                      Booksonfly Commission*
                    </h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="commission"
                      value={formData?.commission}
                      onChange={handleChange}
                    />
                    <h6 className="step-one-title">Shipping Charge*</h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="shippingCharge"
                      value={formData?.shippingCharge}
                      onChange={handleChange}
                    />
                    <h6 className="step-one-title">Quantity*</h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="quantity"
                      value={formData?.quantity}
                      onChange={handleChange}
                    />
                    {/* <h6 className="step-one-title">Item Condition *</h6>
                  <select className="step-one-input">
                    <option value=""></option>
                  </select> */}
                    {/* <h6 className="step-one-title">
                    Click the box below to ad photos! *
                    <span>
                      upload only jpg, png and jpeg files with a max file size of 3MB
                    </span>
                  </h6>
                  <input
                    type="file"
                    hidden
                    id="file"
                    inputprops={{ accept: "image/*" }}
                    name="file"
                    onChange={fileChangedHandler}
                  />
                  <label htmlFor="file" className="file-upload-btn"></label> */}
                    {/* <h6 className="step-one-title">Publisher</h6>
                  <input type="text" className="step-one-input" />
                  <div className="d-flex">
                    <div className="me-5">
                      <h6 className="step-one-title">MRP(₹) *</h6>
                      <input type="text" className="step-one-input" />
                    </div>
                    <div>
                      <h6 className="step-one-title">Publishing Year</h6>
                      <input type="text" className="step-one-input" />
                    </div>
                  </div> */}
                    {/* <div className="d-flex">
                    <div className="me-5">
                      <h6 className="step-one-title">No. of pages</h6>
                      <input type="text" className="step-one-input" />
                    </div>
                    <div>
                      <h6 className="step-one-title">Book Language</h6>
                      <input type="text" className="step-one-input" />
                    </div>
                  </div> */}
                    <h6 className="step-one-title">
                      Add Description
                      <span> Enter long description.</span>
                    </h6>
                    <textarea
                      name="description"
                      value={formData?.description}
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="6"
                      // value={description}
                      // onChange={(e) => setDescription(e.target.value)}
                      className="step-one-input"
                    ></textarea>
                    <h6 className="step-one-title">Website Price*</h6>
                    <input
                      type="text"
                      className="step-one-input"
                      name="webPrize"
                      value={formData?.webPrize}
                      onChange={handleChange}
                    />
                    <div className="bottom-btn d-flex justify-content-end">
                      <button
                        onClick={() => setCurrentSteps("1")}
                        className="prev-btn"
                      >
                        PREVIOUS
                      </button>
                      <button
                        disabled={currentSteps === "2"}
                        className="next-btn"
                      >
                        NEXT
                      </button>
                      <button className="submit-btn" onClick={()=>HandleSubmit()}>
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditSell;
