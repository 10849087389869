import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";

const CartPage = ({ product, getCartData }) => {
  const e = product;

  const handleIncrement = async (productId, quantity) => {
    try {
        const res = await axios.post(
            `${baseUrl}/cart/addproduct?productId=${productId}&type=add&quantity=${quantity}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );
          if (res?.status == 200) {
            getCartData();
          }
    } catch (error) {
        
    }
  };
  const handleDecrement = async (productId, quantity) => {
    try {
        const res = await axios.post(
            `${baseUrl}/cart/addproduct?productId=${productId}&type=subtract&quantity=${quantity}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );
          if (res?.status == 200) {
            getCartData();
          }
    } catch (error) {
        
    }
  };
  return (
    <div>
      <Card
        sx={{
          display: "flex",
          marginBottom: 2,
          justifyContent: "space-between",
        }}
      >
        <CardContent>
          <CardMedia
            component="img"
            alt={e?.product.productName}
            height="140"
            image={e?.product?.displayImages[0]?.url}
            sx={{ width: 140 }}
          />
        </CardContent>
        <CardContent
        // sx={{ flex: "1 0 auto" }}
        >
          <Typography variant="h5" component="div">
            {e?.product?.productName}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Price: {e?.product?.price}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Quantity: {e?.quantity}
          </Typography>
          <div>
            <IconButton onClick={() => handleIncrement(e?.product?._id, e?.quantity+1)}>
              <AddIcon />
            </IconButton>
            <IconButton onClick={() => handleDecrement(e?.product?._id, e?.quantity+1)}>
              <RemoveIcon />
            </IconButton>
          </div>
        </CardContent>
        <CardContent
          sx={
            {
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "flex-end",
            }
          }
        >
          <Typography variant="subtitle1" color="text.secondary">
            Total: ${e?.quantity * e?.product.price}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default CartPage;
