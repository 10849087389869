import React from 'react'
import Header from '../components/header/header'
import ForgotPassword from '../components/loginpage/ForgotPassword'

const ForgetPage = () => {
  return (
    <div>
      <Header />
      <ForgotPassword />
    </div>
  )
}

export default ForgetPage
