import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import RightCard from "../components/right-suggest-card/RightCard";
// import CenterCard from "../components/center-card/CenterCard";
import SidebarContext from "../context/SidebarContext";
import { useLocation } from "react-router-dom";
import CenterOne from "../assets/center-card-1.png";
import Rating from "../components/Rating";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";

function QuestionPage() {
  const side = useContext(SidebarContext);
  const [value, setValue] = useState(2);
  const { LeftSideBar, openLeftSidebar } = side;
  const pathName = useLocation();
  const [quotes, setQuotes] = useState(null);

  useEffect(() => {
    async function getQuotes() {
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/content/getallcontent/likes?content_type=Acadamy Pages`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuotes(res.data.data);
      } catch (e) {}
    }
    getQuotes();
  }, []);

  var allQuotes = null;
  if (quotes !== null) {
    allQuotes = quotes.map((element, index) => {
      return (
        <>
          <div className="col-sm-12 col-lg-6" key={index}>
            <div className="center-card">
              <div className="center-img">
                <img src={CenterOne} alt="" />
              </div>
              <div className="center-card-content">
                <h2 className="center-card-title">
                  SAGE University the best university in Indore and Central
                  India. We offer world-class education with best of amenities
                  making it the top university.
                </h2>
                <div className="d-flex">
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                  <p className="m-0">106 rating</p>
                </div>
                <div className="d-flex align-items-center">
                  <button className="center-btn">
                    {/* <a href={`/product/review-customer/${id}`}>Review</a> */}
                  </button>
                  <button className="center-btn">
                    <a href="/#/book-question">Q&Ans</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });
  }
  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box review-center-box">
          <p className="path-name">{pathName.pathname}</p>
          <div className="row">{allQuotes}</div>
          {/* <CenterCard /> */}
        </div>
        <div className="right-box">
          <h2 className="right-title">Suggest other collage’s</h2>
          <div className="suggest-box">
            <RightCard />
            <RightCard />
            <RightCard />
            <RightCard />
            <RightCard />
          </div>
        </div>
      </section>
    </>
  );
}

export default QuestionPage;
