import React, { useContext } from "react";
import Header from "../header/header";
import LeftSidebar from "../left-sidebar/LeftSidebar";
import BlogDetailCard from "./BlogDetailCard";
import SidebarContext from "../../context/SidebarContext";
import Img from "../../assets/question-card-img.png";
import inFlag from "../../assets/flag-in.png";
import RecImg from "../../assets/recent-writer.png";
import logoProfile from "../../assets/question-card-img.png";
import star from "../../assets/star.svg";
import flag from "../../assets/flag-in.png";
// import rightArrow from "../../assets/right-arrow.svg";
import { useNavigate } from "react-router-dom";
import BlogRightBar from "../../pages/Rightsidebar/BlogRightBar";

function RecentWriter() {
  return (
    <>
      <div className="d-flex align-items-center recent-writer-box">
        <div>
          <img src={RecImg} alt="" />
        </div>
        <div>
          <h2 className="recent-writer-title">Chetan Goyal</h2>
        </div>
      </div>
    </>
  );
}

function TopWriter() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between writer-box">
        <div className="d-flex">
          <div className="d-flex align-self-end">
            <img src={Img} alt="" />
          </div>
          <div className="writer-detail">
            <h2 className="m-0">Chetan Goyal</h2>
            <p className="writer-flag m-0">
              India <img src={inFlag} alt="" />
            </p>
          </div>
        </div>
        <div>
          <button className="view-btn">View</button>
        </div>
      </div>
    </>
  );
}

function BlogDetail() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className="blog-detail-hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/blog");
              }}
            >
              &nbsp;Blog /
            </p>
            <p
              className="path-name-bold m-0"
              onClick={() => {
                navigate(`/blog/blog-detail`);
              }}
            >
              &nbsp; Blog Detail
            </p>
          </div>
          <BlogDetailCard />
        </div>
        <BlogRightBar />
        {/* <div className="right-box">
          <h2 className="right-title text-center">Member Detail</h2>
          <div className="suggest-box">
            <div className="d-flex justify-content-between mb-2 align-items-center">
              <div className="d-flex align-items-center">
                <img src={logoProfile} alt="" />
                <div className="ms-3">
                  <h6 className="all-card-title">Chetan Goyal</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex justify-content-between align-items-center m-0">
                      Rating
                      <img src={star} style={{ margin: "0 10px" }} alt="" />
                      <p className="m-0">4.5</p>
                    </p>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                      <p className="mb-0 me-2">Following</p>
                      <p className="m-0">55</p>
                    </div>
                  </div>
                  <p className="country-name mb-0 mt-2">
                    India <img src={flag} alt="" />
                    <button className="follow-btn ms-3">Follow</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Blogs</p>
              <p className="m-0">5</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Books</p>
              <p className="m-0">1</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Quotes</p>
              <p className="m-0">2</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Q&A</p>
              <p className="m-0">5</p>
            </div>
          </div>
          <div className="suggest-box">
            <ul
              className="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Top Blog
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Recent Blog
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <TopWriter />
                <TopWriter />
                <TopWriter />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
              </div>
            </div>
          </div>
          <h2 className="recent-title text-center">Place for ad</h2>
        </div> */}
      </section>
    </>
  );
}

export default BlogDetail;
