import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginContext from '../../context/login/LoginContext';
import { baseUrl } from '../../context/baseUrl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AlertContainer } from '../Alerts/ToastifyContainer';
import { Grid } from '@mui/material';
import loginImg from "../../assets/book-white-background.jpg";

import ButtonLoader from '../../loader/ButtonLoader';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otp, setOtp] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const LoginStatus = useContext(LoginContext);
    const [loader, setLoader] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [timer, setTimer] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const validateFields = () => {
        let errors = {};
        if (!email) errors.email = "Email is required!";
        if (isActive) {
            if (!password) errors.password = "Password is required!";
            if (!confirmPassword) errors.confirmPassword = "Confirm Password is required!";
            if (password !== confirmPassword) errors.confirmPassword = "Passwords do not match!";
            if (!otp) errors.otp = "OTP is required!";
        }
        return errors;
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors({});
        }

        const raw = JSON.stringify({
            email: email,
            password: password,
            otp: otp
        });
        setLoader(true);
        try {
            const res = await axios({
                method: "post",
                url: baseUrl + "/auth/forgot/submit",
                data: raw,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.status === 200) {
                setLoader(false);
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                setOtp("");
                toast.success("Password is updated");
                setTimeout(() => {
                    LoginStatus.setLoggedIn(true);
                    navigate("/login");
                }, 3000);
            }
        } catch (e) {
            setLoader(false);
            toast.error("Password update failed.");
        }
    }

    const handleSendOtp = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors({});
        }

        setLoader(true);
        try {
            const res = await axios.post(`${baseUrl}/auth/forgot/sendotp`, { email });
            if (res.status === 200) {
                setIsActive(true);
                setLoader(false);
                setTimer(30); // Start the timer for 30 seconds
                toast.success("OTP send to mail");
            }
        } catch (error) {
            setLoader(false);
            toast.error("Failed to send OTP.");
        }
    }

    return (
        <>
            <AlertContainer />
            <section className="login">
                <div className="container">
                    <Grid container alignItems={"center"} rowSpacing={4}>
                        <Grid item md={6} xs={12} lg={6}>
                            <div className="login-img">
                                <img src={loginImg} alt="" />
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12} lg={6}>
                            <div className="login-container">
                                <div className="text-left">
                                    <h2 className="login-title">Forgot Password</h2>
                                    <hr />
                                    <div>
                                        <div className="login-input-container">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="email" className="input-title">Email</label>
                                                <input
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="input-box"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                {errors.email && <span className="error-message" style={{color:"red"}}>{errors.email}</span>}
                                            </div>
                                            {isActive && (
                                                <>
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="otp" className="input-title">OTP</label>
                                                        <input
                                                            value={otp}
                                                            onChange={(e) => setOtp(e.target.value)}
                                                            className="input-box"
                                                            type="text"
                                                            name="otp"
                                                            placeholder="Enter the OTP"
                                                        />
                                                        {errors.otp && <span className="error-message" style={{color:"red"}}>{errors.otp}</span>}
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="password" className="input-title">New Password</label>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                className="input-box"
                                                                type={showPassword ? "text" : "password"}
                                                                name="password"
                                                                placeholder="New Password"
                                                            />
                                                            <span
                                                                className="toggle-password"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {showPassword ? "Hide" : "Show"}
                                                            </span>
                                                        </div>
                                                        {errors.password && <span className="error-message" style={{color:"red"}}>{errors.password}</span>}
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <label htmlFor="confirmPassword" className="input-title">Confirm Password</label>
                                                        <div style={{ position: "relative" }}>
                                                            <input
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                className="input-box"
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                name="confirmPassword"
                                                                placeholder="Confirm Password"
                                                            />
                                                            {/* <span
                                                                className="toggle-password"
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "10px",
                                                                    top: "50%",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {showConfirmPassword ? "Hide" : "Show"}
                                                            </span> */}
                                                        </div>
                                                        {errors.confirmPassword && <span className="error-message" style={{color:"red"}}>{errors.confirmPassword}</span>}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {isActive ? (
                                            <button
                                                className="submit-content-btn"
                                                onClick={handleOnSubmit}
                                            >
                                                {loader?<ButtonLoader/> :"Submit"}
                                            </button>
                                        ) : (
                                            <button
                                                className="submit-content-btn"
                                                onClick={handleSendOtp}
                                                disabled={timer > 0}
                                            >
                                                {loader?<ButtonLoader/> : timer > 0 ? `Resend OTP in ${timer}s` : 'Send OTP'}
                                            </button>
                                        )}
                                        <p className="mt-4">
                                            Don't have an account? &nbsp;
                                            <Link
                                                to="/login"
                                                className="submit-content-btn"
                                                style={{ padding: "6px 26px" }}
                                            >
                                                Login
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            {loader && (
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
            )}
        </>
    );
}

export default ForgotPassword;
