export const capitalizeWords = (str)=> {
    return str
      ?.split(' ')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(' ');
}

export const capitalizeFirstLetter = (str)=> {
    return str
      ?.split(' ')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');
}


export const capitalizeParagraph = (str) => {
  return str
    ?.split('. ')
    ?.map(sentence => sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase())
    ?.join('. ');
};