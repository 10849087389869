import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import BookReviewCard from "../components/book-review/BookReviewCard";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import Rating from "../components/Rating";

function BookReview() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [books, setBooks] = useState(null);
  const [random, setRandom] = useState(null);
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleCategory = (selectedCategoryId) => {
    setCategory(selectedCategoryId);

    // Find the selected category and set its subcategories
    const selectedCategoryData = categoryData.find(
      (cat) => cat._id === selectedCategoryId
    );
    setSubCategories(
      selectedCategoryData ? selectedCategoryData.subcategories : []
    );
    setSubCategory(""); // Reset subcategory when category changes
  };

  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  useEffect(() => {
    async function getData() {
      setLoader(true);
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/content/getallcontent/type/Books`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setBooks(res.data.data);
        const sortedByRating = res.data.data.sort((a, b) => b.rating - a.rating);
        const topFiveBooks = sortedByRating.slice(0, 5);
        setRandom(topFiveBooks);
      } catch (e) {
        console.error(e);
      }
      setLoader(false);
    }
    getData();
  }, []);

  async function getCategory(type = "books") {
    setLoader(true);
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/category/all?contentType=books`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoader(false);
  }

  useEffect(() => {
    getCategory();
  }, []);

  let filteredbooks = books;
  if (category !== "") {
    filteredbooks = filteredbooks.filter((book) => book.category === category);
    if (subCategory !== "") {
      filteredbooks = filteredbooks.filter(
        (book) => book.sub_category === subCategory
      );
    }
  }

  var allBookCard = null;
  if (books !== null) {
    allBookCard = filteredbooks?.map((element, index) => {
      return (
        <BookReviewCard
          key={index}
          index={index}
          heading={element.heading}
          bookId={element._id}
          description={element.description}
          likes={element.likes}
          likesCount={element.likesCount}
          comments={element.commentArray}
          like={element}
          book={element}
        />
      );
    });
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
        <div className="ct-boxes">

        <div className="n-e-w path-box">
  {/* Category Filter as Dropdown */}
  <div className="categories-container">
    <select
      className="form-select"
      onChange={(e) => handleCategory(e.target.value)}
      value={category}
    >
      <option value="">Select a Category</option>
      {categoryData &&
        categoryData.length > 0 &&
        categoryData.map((categoryItem) => (
          <option key={categoryItem._id} value={categoryItem._id}>
            {categoryItem.categoryName}
          </option>
        ))}
    </select>

    
    <select
      onChange={handleSubCategory}
      value={subCategory}
      className="form-select"
    >
      <option value="">{subCategories.length > 0 ? "All Subcategories" : "None"}</option>
      {subCategories.length > 0 ? (
        subCategories.map((subCat, index) => (
          <option value={subCat} key={index}>
            {subCat}
          </option>
        ))
      ) : (
        <option value="none" disabled>
          None
        </option>
      )}
    </select>
  </div>
</div>

<div className="n-e-w path-box">
<div className="categories-container">
         <select
      className="form-select"
     
    >
    <option value="top-rated">Top Rated</option>
    <option value="recent">Recent</option>

      
        
    </select>
    </div>
    </div>
    </div>


          <div className="row gy-4">{allBookCard}</div>
        </div>



        <div className="right-box mt-3">
          <h2 className="right-title">Recent book review</h2>
          <div className="recent-box">
            {random &&
              random.length > 0 &&
              random.map((e) => (
                <div
                  key={e._id}
                  className="d-flex align-items-center review-card div justify-content-between"
                >
                  <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      navigate(`/book/book-detail/${e?._id}`);
                    }}
                  >
                    <img
                      src={e?.displayImage || bookReview}
                      alt=""
                      width={50}
                    />
                    <div className="ms-2">
                      <p className="m-0 review-card-text">{e?.heading}</p>
                      <Rating rating={(e?.rating || 0) / (e?.noOfRates)} />
                    </div>
                  </div>
                  <p className="m-0 review-rating-text">
                    {e?.noOfRates > 0 && e?.noOfRates} Rate
                  </p>
                </div>
              ))}
          </div>
          <h2 className="right-title text-center mt-3">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default BookReview;
