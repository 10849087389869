import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import flag from "../assets/flag-in.png";
import logoProfile from "../assets/question-card-img.png";
import star from "../assets/star.svg";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Buy1 from "../assets/buy-1.svg";
import like from "../assets/Heart.png";
import liked from "../assets/liked-btn.svg";
import { baseUrl } from "../context/baseUrl";

function BuyCard({
  productName,
  id,
  productPrice,
  displayImages,
  description,
  index,
  handleWishlistData,
  wishlistData,
}) {
  const navigate = useNavigate();

  const isWhishlist = (productId) => {
    return wishlistData?.some((product) => product._id === productId);
  };
  const handleaddtowishlist = async (type, productId) => {
    try {
      const userId = localStorage.getItem("userId");
      const res = await axios.post(`${baseUrl}/wishlist/manage/${userId}`, {
        type,
        productId,
      });
      if (res?.status == 200) {
        handleWishlistData();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
     
        <div
          className="buy-card"
         style={{position:'relative'}}
          onClick={() => navigate(`/product/review-customer/${id}`)}
        >
           <div className="like-btn" style={{position:'absolute',zIndex:'100' , right:"10px"}}>
          {isWhishlist(id) ? (
            <img
              src={liked}
              alt="Liked"
              onClick={() => {
                handleaddtowishlist("delete", id);
              }}
            />
          ) : (
            <img
              src={like}
              alt="Not Liked"
           

              onClick={() => {
                handleaddtowishlist("add", id);
                
              }}
            />
          )}
        </div>
          <div className="pd-center">
          <img src={Buy1} alt="" />
          </div>
          <h6 className="buy-card-title">{productName}</h6>
          <p className="buy-card-text">{description}</p>
          <div style={{ marginLeft: "10px", display: "flex", gap: "5px" }}>
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <p
              className="m-0"
              style={{
                color: "#8C8C8C",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              106 rating
            </p>
          </div>
          <p className="m-0 product-price">Rs. {productPrice}</p>
          <div className="d-flex">
            <p className="m-0 product-discount-price">
              <del>$459.00</del>
            </p>
            <p className="m-0 product-discount-price">(48% off)</p>
          </div>
        </div>
      </div>
    </>
  );
}

function BuyPage() {
  const [products, setProducts] = useState([]);
  const userId = localStorage.getItem("userId");
  const [wishlistData, setWishlistData] = useState([]);
  const getWishListData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const res = await axios.get(`${baseUrl}/wishlist/get/${userId}`);
      setWishlistData(res?.data?.data?.products);
    } catch (error) {}
  };
  useEffect(() => {
    getWishListData();
    getProducts();
  }, []);
  async function getProducts() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/product/getallproduct`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setProducts(res.data.data);
    } catch (e) {}
  }

  const handleWishlistData = async () => {
    getWishListData();
  };
  const raw = { toFollowId: userId };

  //   async function handleFollow() {
  //     try {
  //       const res = await axios({
  //         method: "post",
  //         url: `https://books-on-fly.vercel.app/api/user/updateFollower/${userId}`,
  //         data: raw,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       });
  //       setProducts(res.data.data);
  //     } catch (e) {}
  //   }

  // if (products === null) {
  //   return <></>;
  // }

  return (
    <>
      <Header />
     
      <section className="hero-section">
        <div className="center-box">
        <br/>
     
          <div className="d-flex align-items-center path-box">
            <p className="path-name m-0">Home /</p>
            <p className="path-name-bold m-0">&nbsp; Product</p>
          </div>
          <div className="buy-container">
            <div className="row">
              {products.map((element, index) => {
                return (
                  <>
                    <BuyCard
                      index={index}
                      id={element._id}
                      productName={element.productName}
                      productPrice={element.price}
                      description={element.description}
                      displayImages={element.displayImages}
                      wishlistData={wishlistData}
                      handleWishlistData={handleWishlistData}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right-box">
        <br/>
          <h2 className="right-title text-center">Member Details</h2>
          <div className="follow-profile">
            <div className="d-flex justify-content-between mb-2 align-items-center">
              <div className="d-flex align-items-center">
                <img src={logoProfile} alt="" />
                <div className="ms-3">
                  <h6 className="all-card-title">Chetan Goyal</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex justify-content-between align-items-center m-0">
                      Rating
                      <img src={star} style={{ margin: "0 10px" }} alt="" />
                      <p className="m-0">4.5</p>
                    </p>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                      <p className="mb-0 me-2">Following</p>
                      <p className="m-0">55</p>
                    </div>
                  </div>
                  <p className="country-name mb-0 mt-2">
                    India <img src={flag} alt="" />
                    <button className="follow-btn ms-3">Follow</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Blogs</p>
              <p className="m-0">5</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Book Review</p>
              <p className="m-0">1</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Quotes</p>
              <p className="m-0">2</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Q&A</p>
              <p className="m-0">5</p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Recent book review</h2>
          <div className="top-member-card">
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
    </>
  );
}

export default BuyPage;
