import React, { useState } from "react";
import BlogContext from "./BlogContext";


const BlogState = (props) => {
    const [blogComment, setBlogComment] = useState([]);
    const [blogLike, setBlogLike] = useState([]);
    return(
        <BlogContext.Provider value={{blogComment, setBlogComment, blogLike, setBlogLike}}>
            {props.children}
        </BlogContext.Provider>
    )
}

export default BlogState;