import React, { useContext, useState } from "react";
import Img from "../../assets/question-card-img.png";
import axios from "axios";
import moment from "moment";
import LoginContext from "../../context/login/LoginContext";
import { Link, useNavigate } from "react-router-dom";
import QuotesContext from "../../context/quetes/QuotesContext";
import { baseUrl } from "../../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../../common/toUpperCase";
import flag from "../../assets/flag-in.png";
import { toast } from "react-toastify";

function QuotesCard({
  author,
  description,
  date,
  likes,
  title,
  comments,
  quotesId,
  likesCount,
  like,
  userData,
  handleFollow
}) {
  const loginuserId = localStorage.getItem("userId") 
  const login = useContext(LoginContext);
  const { loggedIn } = login;
  const navigate = useNavigate();
  const quotes = useContext(QuotesContext);
  const { quotesComment, setQuotesComment, quotesLike, setQuotesLike } = quotes;
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [commentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState("");
  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }

  async function handleOnComment(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          comment: comment,
        });
        const res = await axios({
          method: "put",
          url: `/comments/postcomment/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setQuotesComment(res.data.data.commentArray);
        setNewComment(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setQuotesLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }

  
  const handleFollowCheck = (userId) => {
    const follow = userData?.following?.includes(userId);
    return follow || false;
  }

  let allComment;
  if (newComment === false) {
    allComment = comments.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  } else {
    allComment = quotesComment.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  }
  return (
    <>
    <div className="bg-card">
<div className="bg-card-img"  onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)}>
<div className="bg-card-img-heading">
<img src={like?.userId?.profileImage || Img} alt="" style={{width:"70px", height:"70px", borderRadius:"50px"}} />

<div className="flag-name">
<b>

<p className="">{capitalizeWords(like?.author)}</p>
            </b>
<div className="question-time">
              {capitalizeWords(like?.userId?.country)||"India"}
              <img src={flag} className="ps-2" alt="" />
            </div>
          

</div>
</div>
<div>
<div>
  <button className="followbtn">    
  <li style={{listStyle:'none'}}>
                  {like?.userId?._id === loginuserId ? (
                    <a>Self</a>
                  ) : (
                    <a onClick={() => handleFollow(like?.userId?._id)}>
                      {handleFollowCheck(like?.userId?._id)?"Following":"Follow"}
                    </a>
                  )}
                </li>
                </button>
</div>
</div>



</div>


<h1  onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)} className="" style={{fontSize:'20px',marginTop:'20px'}}>{capitalizeParagraph(title)}</h1>

<div  onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)} dangerouslySetInnerHTML={{ __html: description }}></div>

<div  onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)} className="blog-dates">
<p  onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)} className="">
                 {moment(date).format("DD MMMM YYYY")}
                </p>
<p className="">{capitalizeWords(like?.author)}</p>

</div>

<div className="likes-comments">

<div
                className="like d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => handleOnLike(quotesId)}
              >
                {likes.includes(localStorage.getItem("userId")) ? (
                  <i className="fa fa-solid fa-heart"></i>
                ) : (
                  <i className="bi bi-heart"></i>
                )}{" "}
                {newLike === false
                  ? likesCount + " " + "Like"
                  : quotesLike.length + " " + "Like"}
              </div>
              <div
                className="comment d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)}
              >
                <i className="bi bi-chat"></i>
                {comments.length + " "}
                Comments
              </div>
</div>






</div>

      {/* <div
        className="quotes-card"
        style={
          commentBox === true
            ? { marginBottom: "0px" }
            : { marginBottom: "18px" }
        }
      >
        <div className="d-flex">
          <div>
          <img src={like?.userId?.profileImage || Img} alt="" style={{width:"50px", height:"50px", borderRadius:"50px"}} />
          </div>
          <div className="d-flex flex-column ps-3">
            <div className="d-flex align-items-center">
              <p className="question-title m-0" onClick={()=>navigate(`/member/${like?.userId?._id}`)}>{capitalizeWords(like?.userId?.nickName)}</p>
              <ul className="m-0">
              <li>
                  {like?.userId?._id === loginuserId ? (
                    <a>Self</a>
                  ) : (
                    <a onClick={() => handleFollow(like?.userId?._id)}>
                      {handleFollowCheck(like?.userId?._id)?"Following":"Follow"}
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className="question-time">
              {capitalizeWords(like?.userId?.country)||"India"}
              <img src={flag} className="ps-2" alt="" />
            </div>
            <div className="question-time">
              <i className="bi bi-clock"></i> 5 hrs
            </div>
          </div>
        </div>
        <Link
          to={`/quotes/quotes-detail/${quotesId}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <h1 className="question-card-title mb-0 text-left">{capitalizeParagraph(title)}</h1>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Link>
        <p className="author-text">{capitalizeWords(like?.author)}</p> */}
        {/* <p className="question-card-text">
          ANS :- Public facilities are services provided by the government to
          its citizens. Some of the important public facilities include
          infrastructure, sanitation, public transport, health care, water, etc.
          The government's main objective is to provide these public facilities
          to citizens and businesses to manage social and economic activities
          more smoothly.ANS :-Public facilities are services provided by the
          government to its citizens. Some of the important public facilities
          include infrastructure, sanitation, public transport, health care,
          water, etc. Read more.....
        </p> */}
        {/* <div className="line"></div>
        <div className="d-flex align-items-center justify-content-between pt-3">
          <div className="d-flex align-items-center">
            <div
              className="like d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => handleOnLike(quotesId)}
            >
              {likes.includes(localStorage.getItem("userId")) ? (
                <i className="fa fa-solid fa-heart"></i>
              ) : (
                <i className="bi bi-heart"></i>
              )}{" "}
              {newLike === false
                ? likesCount + " " + "Like"
                : quotesLike.length + " " + "Like"}
            </div>
            <div
              className="comment d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/quotes/quotes-detail/${quotesId}`)}
            >
              <i className="bi bi-chat"></i>{" "}
              {newComment === false
                ? comments.length + " "
                : quotesComment.length + " "}
              Comment
            </div>
          </div>
          <div className="calender">
            <i className="bi bi-calendar2-week pe-2"></i>
            {moment(date).format("DD/MM/YYYY")}
          </div>
        </div>
      </div> */}
      {/* {commentBox && (
        <div className="comment-boxes">
          <div className="d-flex align-items-center">
            <img src={Img} alt="" className="comment-profile" />
            <input
              className="w-100 mx-3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add your comment....."
            />
            <button
              className="blog-comment-btn"
              onClick={() => handleOnComment(quotesId)}
            >
              Comment
            </button>
          </div>
        </div>
      )} */}
    </>
  );
}

export default QuotesCard;
