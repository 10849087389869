import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "./config/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "font-awesome/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouterProvider } from "react-router-dom";
import SidebarState from "./context/SidebarState";
import LoginState from "./context/login/LoginState";
import BlogState from "./context/blog/BlogState";
import ReaderState from "./context/reader/ReaderState";
import QuotesState from "./context/quetes/QuotesState";
import BookState from "./context/book/BookState";
import SellState from "./context/sell/SellState";
import { AlertContainer } from "./components/Alerts/ToastifyContainer";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <LoginState>
    <SidebarState>
      <BlogState>
        <BookState>
          <ReaderState>
            <QuotesState>
              <SellState>
                <AlertContainer />
                <RouterProvider router={router} />
              </SellState>
            </QuotesState>
          </ReaderState>
        </BookState>
      </BlogState>
    </SidebarState>
  </LoginState>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
