import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import SellContext from "../../context/sell/SellContext";
import { baseUrl } from "../../context/baseUrl";

function StepOne({ currentSteps, setCurrentSteps }) {
  const side = useContext(SellContext);
  const { productName, setProductName, setTypes, initialValue } = side;

  const [typesData, setTypesData] = useState(null);
  const [showPreview, setShowPreview] = useState(null);
  const [formData, setFormData] = useState(initialValue);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const handleImage = (e, img) => {
    // e.preventDefault();
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setShowPreview({ ...showPreview, [img]: objectUrl });
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  function handleOnNext() {
    setCurrentSteps("2");
  }

  async function getTypes() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/producttype/getalltype`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTypesData(res.data.data);
    } catch (e) {}
  }
  useEffect(() => {
    getTypes();
  }, []);

  if (typesData === null) {
    return <></>;
  }
  function selectType(e) {
    setTypes(e.target.value);
  }

  return (
    <>
      <div className="step-one-container">
        <h6 className="step-one-title">Upload Images</h6>
        <div className="file-upload">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                  {formData?.image1 === null ? (
                    <>
                      <input
                        id="img-1"
                        hidden
                        type="file"
                        onChange={(e) => {
                          handleImage(e, "image1");
                        }}
                      />
                      <label htmlFor="img-1" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div className="preview-img">
                        <img alt="" height="100px" src={showPreview.image1} />
                      </div>
                      <div>
                        <button
                          className="remove-btn w-50"
                          onClick={() =>
                            setFormData({ ...formData, image1: null })
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg">
              <div className="d-flex flex-column">
                <div className="d-flex-flex-column">
                  {formData?.image2 === null ? (
                    <>
                      <input
                        id="img-2"
                        hidden
                        type="file"
                        onChange={(e) => {
                          handleImage(e, "image2");
                        }}
                      />
                      <label htmlFor="img-2" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div className="preview-img">
                        <img alt="" height="100px" src={showPreview.image2} />
                      </div>
                      <div>
                        <button
                          className="remove-btn w-50"
                          onClick={() =>
                            setFormData({ ...formData, image2: null })
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg">
              <div className="d-flex flex-column">
                <div className="d-flex-flex-column">
                  {formData?.image3 === null ? (
                    <>
                      <input
                        id="img-3"
                        hidden
                        type="file"
                        onChange={(e) => {
                          handleImage(e, "image3");
                        }}
                      />
                      <label htmlFor="img-3" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div className="preview-img">
                        <img alt="" height="100px" src={showPreview.image3} />
                      </div>
                      <div>
                        <button
                          className="remove-btn w-50"
                          onClick={() =>
                            setFormData({ ...formData, image3: null })
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg">
              <div className="d-flex flex-column">
                <div className="d-flex-flex-column">
                  {formData?.image4 === null ? (
                    <>
                      <input
                        id="img-4"
                        hidden
                        type="file"
                        onChange={(e) => {
                          handleImage(e, "image4");
                        }}
                      />
                      <label htmlFor="img-4" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div className="preview-img">
                        <img alt="" height="100px" src={showPreview.image4} />
                      </div>
                      <div>
                        <button
                          className="remove-btn w-50"
                          onClick={() =>
                            setFormData({ ...formData, image4: null })
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg">
              <div className="d-flex flex-column">
                <div className="d-flex-flex-column">
                  {formData?.image5 === null ? (
                    <>
                      <input
                        id="img-5"
                        hidden
                        type="file"
                        onChange={(e) => {
                          handleImage(e, "image5");
                        }}
                      />
                      <label htmlFor="img-5" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div className="preview-img">
                        <img alt="" height="100px" src={showPreview.image5} />
                      </div>
                      <div>
                        <button
                          className="remove-btn w-50"
                          onClick={() =>
                            setFormData({ ...formData, image5: null })
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 className="step-one-title">Product Nameeee</h6>
        <input
          type="text"
          className="step-one-input"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        <h6 className="step-one-title">Category</h6>
        <input
          type="text"
          className="step-one-input"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <h6 className="step-one-title">Sub Category</h6>
        <input
          type="text"
          className="step-one-input"
          value={subCategory}
          onChange={(e) => setSubCategory(e.target.value)}
        />
        <h6 className="step-one-title">
          Type * <span>Select suitable category for your ad</span>
        </h6>
        <select onChange={selectType} className="step-one-input">
          {typesData.map((element, index) => {
            return (
              <>
                <option key={index} value={element._id}>
                  {element.productType}
                </option>
              </>
            );
          })}
        </select>
        {/* <h6 className="step-one-title">
          <span>Select suitable category for your ad</span>
        </h6>
        <select className="step-one-input">
          <option value=""></option>
        </select> */}
        <div className="bottom-btn d-flex justify-content-end">
          <button disabled={currentSteps === "1"} className="prev-btn">
            PREVIOUS
          </button>
          <button onClick={handleOnNext} className="next-btn">
            NEXT
          </button>
        </div>
      </div>
    </>
  );
}

export default StepOne;
