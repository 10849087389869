import React, { useContext, useState } from "react";
import SellContext from "../../context/sell/SellContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../context/baseUrl";

function StepTwo({ currentSteps, setCurrentSteps }) {
  const side = useContext(SellContext);
  const { productName, selectedFile, types } = side;
  const navigate = useNavigate();

  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [weight, setWeight] = useState("");
  const [dimension, setDimension] = useState("");
  const [description, setDescription] = useState("");
  const [commission, setCommission] = useState("");
  const [sipping, setSipping] = useState("");
  const [WPrice, setWPrice] = useState("");
  // const [file, setFile] = useState(null);
  // const fileChangedHandler = (event) => {
  //   let file = event.target.files[0];
  //   let reader = new FileReader();
  //   reader.onload = function (e) {
  //     setFile(e.target.result);
  //   };
  //   reader.readAsDataURL(event.target.files[0]);
  //   if (file.size > 10e6) {
  //     window.alert("Please upload a file smaller than 10 MB");
  //     return false;
  //   }
  // };

  async function HandleSubmit() {
    const body = {
      productName: productName,
      description: description,
      price: price,
      productType: types,
      quantity: quantity,
      images: selectedFile,
      isOther: "true",
      otherCategory: "OTHER",
    };
    try {
      const res = await axios({
        method: "post",
        url: `${baseUrl}/product/create`,
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      navigate("/");
    } catch (e) {}
  }

  function handleOnPrev() {
    setCurrentSteps("1");
  }
  return (
    <>
      <div className="step-two-container">
        {/* <h6 className="step-one-title">Type of Ad *</h6>
        <select className="step-one-input">
          <option value=""></option>
        </select> */}
        {/* <h6 className="step-one-title">Price Type *</h6>
        <select className="step-one-input">
          <option value=""></option>
        </select> */}
        <h6 className="step-one-title">Price ₹ only *</h6>
        <input
          type="text"
          className="step-one-input"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <h6 className="step-one-title">Weight*</h6>
        <select className="step-one-input">
          <option value="">Select Weight</option>
          <option value="< 0.5 KG">0.5 KG</option>
          <option value="0.5KG - 1 KG">0.5KG - 1 KG</option>
          <option value="1KG - 1 KG">1KG - 2 KG</option>
          <option value="2KG - 1 KG">2KG - 3 KG</option>
          <option value="3KG - 1 KG">3KG - 4 KG</option>
          <option value="4KG - 1 KG">4KG - 5 KG</option>
          <option value="5KG - 1 KG">5KG - 6 KG</option>
          <option value="6KG - 1 KG">6KG - 7 KG</option>
          <option value="7KG - 1 KG">7KG - 8 KG</option>
          <option value="8KG - 1 KG">8KG - 9 KG</option>
          <option value="9KG - 1 KG">9KG - 10 KG</option>
        </select>
        <h6 className="step-one-title">Dimension*</h6>
        <div className="row">
          <div className="col-4">
            <div className="d-flex align-items-center justify-content-center">
              <h6 className="step-one-title m-0">Length*</h6>
              <select className="step-one-input m-0 mx-3" style={{width: "50%"}}>
                <option value="">Select cms</option>
                <option value="< 0.5 KG">0.5 KG</option>
                <option value="0.5KG - 1 KG">0.5KG - 1 KG</option>
                <option value="1KG - 1 KG">1KG - 2 KG</option>
                <option value="2KG - 1 KG">2KG - 3 KG</option>
                <option value="3KG - 1 KG">3KG - 4 KG</option>
                <option value="4KG - 1 KG">4KG - 5 KG</option>
                <option value="5KG - 1 KG">5KG - 6 KG</option>
                <option value="6KG - 1 KG">6KG - 7 KG</option>
                <option value="7KG - 1 KG">7KG - 8 KG</option>
                <option value="8KG - 1 KG">8KG - 9 KG</option>
                <option value="9KG - 1 KG">9KG - 10 KG</option>
              </select>
              <h6 className="step-one-title m-0">cm</h6>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center justify-content-center">
              <h6 className="step-one-title m-0">Width*</h6>
              <select className="step-one-input m-0 mx-3" style={{width: "50%"}}>
                <option value="">Select cms</option>
                <option value="< 0.5 KG">0.5 KG</option>
                <option value="0.5KG - 1 KG">0.5KG - 1 KG</option>
                <option value="1KG - 1 KG">1KG - 2 KG</option>
                <option value="2KG - 1 KG">2KG - 3 KG</option>
                <option value="3KG - 1 KG">3KG - 4 KG</option>
                <option value="4KG - 1 KG">4KG - 5 KG</option>
                <option value="5KG - 1 KG">5KG - 6 KG</option>
                <option value="6KG - 1 KG">6KG - 7 KG</option>
                <option value="7KG - 1 KG">7KG - 8 KG</option>
                <option value="8KG - 1 KG">8KG - 9 KG</option>
                <option value="9KG - 1 KG">9KG - 10 KG</option>
              </select>
              <h6 className="step-one-title m-0">cm</h6>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center justify-content-center">
              <h6 className="step-one-title m-0">Height*</h6>
              <select className="step-one-input m-0 mx-3" style={{width: "50%"}}>
                <option value="">Select cms</option>
                <option value="< 0.5 KG">0.5 KG</option>
                <option value="0.5KG - 1 KG">0.5KG - 1 KG</option>
                <option value="1KG - 1 KG">1KG - 2 KG</option>
                <option value="2KG - 1 KG">2KG - 3 KG</option>
                <option value="3KG - 1 KG">3KG - 4 KG</option>
                <option value="4KG - 1 KG">4KG - 5 KG</option>
                <option value="5KG - 1 KG">5KG - 6 KG</option>
                <option value="6KG - 1 KG">6KG - 7 KG</option>
                <option value="7KG - 1 KG">7KG - 8 KG</option>
                <option value="8KG - 1 KG">8KG - 9 KG</option>
                <option value="9KG - 1 KG">9KG - 10 KG</option>
              </select>
              <h6 className="step-one-title m-0">cm</h6>
            </div>
          </div>
        </div>
        <h6 className="step-one-title mt-4">Booksonfly Commission*</h6>
        <input
          type="text"
          className="step-one-input"
          value={commission}
          onChange={(e) => setCommission(e.target.value)}
        />
        <h6 className="step-one-title">Shipping Charge*</h6>
        <input
          type="text"
          className="step-one-input"
          value={sipping}
          onChange={(e) => setSipping(e.target.value)}
        />
        <h6 className="step-one-title">Quantity*</h6>
        <input
          type="text"
          className="step-one-input"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        {/* <h6 className="step-one-title">Item Condition *</h6>
        <select className="step-one-input">
          <option value=""></option>
        </select> */}
        {/* <h6 className="step-one-title">
          Click the box below to ad photos! *
          <span>
            upload only jpg, png and jpeg files with a max file size of 3MB
          </span>
        </h6>
        <input
          type="file"
          hidden
          id="file"
          inputprops={{ accept: "image/*" }}
          name="file"
          onChange={fileChangedHandler}
        />
        <label htmlFor="file" className="file-upload-btn"></label> */}
        {/* <h6 className="step-one-title">Publisher</h6>
        <input type="text" className="step-one-input" />
        <div className="d-flex">
          <div className="me-5">
            <h6 className="step-one-title">MRP(₹) *</h6>
            <input type="text" className="step-one-input" />
          </div>
          <div>
            <h6 className="step-one-title">Publishing Year</h6>
            <input type="text" className="step-one-input" />
          </div>
        </div> */}
        {/* <div className="d-flex">
          <div className="me-5">
            <h6 className="step-one-title">No. of pages</h6>
            <input type="text" className="step-one-input" />
          </div>
          <div>
            <h6 className="step-one-title">Book Language</h6>
            <input type="text" className="step-one-input" />
          </div>
        </div> */}
        <h6 className="step-one-title">
          Add Description
          <span> Enter long description.</span>
        </h6>
        <textarea
          name=""
          id=""
          cols="30"
          rows="6"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="step-one-input"
        ></textarea>
        <h6 className="step-one-title">Website Price*</h6>
        <input
          type="text"
          className="step-one-input"
          value={WPrice}
          onChange={(e) => setWPrice(e.target.value)}
        />
        <div className="bottom-btn d-flex justify-content-end">
          <button onClick={handleOnPrev} className="prev-btn">
            PREVIOUS
          </button>
          <button disabled={currentSteps === "2"} className="next-btn">
            NEXT
          </button>
          <button className="submit-btn" onClick={HandleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}

export default StepTwo;
