import React, { useContext, useState } from "react";
import Img from "../../assets/question-card-img.png";
import flag from "../../assets/flag-in.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import BlogContext from "../../context/blog/BlogContext";
import { baseUrl } from "../../context/baseUrl";
import { capitalizeParagraph, capitalizeWords } from "../../common/toUpperCase";

function BlogCard({
  author,
  description,
  date,
  likes,
  title,
  comments,
  blogId,
  likesCount,
  like,
  userData,
  handleFollow
}) {
  const loginuserId = localStorage.getItem("userId") 
  const login = useContext(LoginContext);
  const { loggedIn } = login;
  const blogComments = useContext(BlogContext);
  const { blogLike, setBlogLike } = blogComments;
  // const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const navigate = useNavigate();
  // const [commentBox, setCommentBox] = useState(false);
  // function toggleCommentBox() {
  //   setCommentBox(!commentBox);
  // }
  // async function handleOnComment(id) {
  //   if (loggedIn === true) {
  //     try {
  //       const body = JSON.stringify({
  //         comment: comment,
  //       });
  //       const res = await axios({
  //         method: "put",
  //         url: `${baseUrl}/comments/postcomment/${id}`,
  //         data: body,
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       });
  //       setComment("");
  //       setBlogComment(res.data.data.commentArray);
  //       setNewComment(true);
  //     } catch (e) {}
  //   } else {
  //     navigate("/login");
  //   }
  // }


  const handleFollowCheck = (userId) => {
    const follow = userData?.following?.includes(userId);
    return follow || false;
  }

  async function handleOnLike(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setBlogLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  // let allComment;
  // if (newComment === false) {
  //   allComment = comments.map((element, index) => {
  //     return (
  //       <>
  //         <div className="d-flex">
  //           <img src={Img} alt="" className="comment-profile" />
  //           <div className="ms-3">
  //             <p className="comment-text">{element.comment}</p>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   });
  // } else {
  //   allComment = blogComment.map((element, index) => {
  //     return (
  //       <>
  //         <div className="d-flex">
  //           <img src={Img} alt="" className="comment-profile" />
  //           <div className="ms-3">
  //             <p className="comment-text">{element.comment}</p>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   });
  // }
  return (
    <>

<div className="bg-card" >

<div className="bg-card-img" >
      <div className="bg-card-img-heading">
      <img onClick={() => navigate(`/member/${blogId}`)} src={like?.userId?.profileImage || Img} alt="" style={{width:"70px", height:"70px", borderRadius:"50px"}} />
<div className="flag-name" onClick={() => navigate(`/blog/blog-detail/${blogId}`)}>
<b>

<p className="">{capitalizeWords(like?.author)}</p>
            </b>
            
<div className="question-time">
              {capitalizeWords(like?.userId?.country)||"India"}
              <img src={flag} className="ps-2" alt="" />
            </div>
          

</div>
      </div>

<div>
  <button className="followbtn">    
  <li style={{listStyle:'none'}}>
                  {like?.userId?._id === loginuserId ? (
                    <a>Self</a>
                  ) : (
                    <a onClick={() => handleFollow(like?.userId?._id)}>
                      {handleFollowCheck(like?.userId?._id)?"Following":"Follow"}
                    </a>
                  )}
                </li>
                </button>
</div>


</div>


<h1 onClick={() => navigate(`/blog/blog-detail/${blogId}`)} className="" style={{fontSize:'20px',marginTop:'20px'}}>{capitalizeParagraph(title)}</h1>
<div onClick={() => navigate(`/blog/blog-detail/${blogId}`)} dangerouslySetInnerHTML={{ __html: description }}></div>

<div onClick={() => navigate(`/blog/blog-detail/${blogId}`)} className="blog-dates">
<p className="">
                 {moment(date).format("DD MMMM YYYY")}
                </p>
</div>

<div className="likes-comments">

<div
                className="like d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => handleOnLike(blogId)}
              >
                {likes.includes(localStorage.getItem("userId")) ? (
                  <i className="fa fa-solid fa-heart"></i>
                ) : (
                  <i className="bi bi-heart"></i>
                )}{" "}
                {newLike === false
                  ? likesCount + " " + "Like"
                  : blogLike.length + " " + "Like"}
              </div>
              <div
                className="comment d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/blog/blog-detail/${blogId}`)}
              >
                <i className="bi bi-chat"></i>
                {comments.length + " "}
                Comments
              </div>
</div>






</div>






      {/* <div className="question-card">
        <div className="d-flex">
          <div>
            <img src={like?.userId?.profileImage || Img} alt="" style={{width:"50px", height:"50px", borderRadius:"50px"}} />
          </div>
          <div className="d-flex flex-column ps-3">
            <div className="d-flex align-items-center" >
              <p className="question-title m-0" onClick={()=>navigate(`/member/${like?.userId?._id}`)} >{capitalizeWords(like?.userId?.nickName)}</p>
              <ul className="m-0">
              <li>
                  {like?.userId?._id === loginuserId ? (
                    <a>Self</a>
                  ) : (
                    <a onClick={() => handleFollow(like?.userId?._id)}>
                      {handleFollowCheck(like?.userId?._id)?"Following":"Follow"}
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className="question-time">
              {capitalizeWords(like?.userId?.country)||"India"}
              <img src={flag} className="ps-2" alt="" />
            </div>
          </div>
        </div>
        <Link
          to={`/blog/blog-detail/${blogId}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <h1 className="question-card-title mb-0">{capitalizeParagraph(title)}</h1>
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Link>
        <p className="author-text">{capitalizeWords(like?.author)}</p>
        <div className="line"></div>

        <p className="question-card-text">
          ANS :- Public facilities are services provided by the government to
          its citizens. Some of the important public facilities include
          infrastructure, sanitation, public transport, health care, water, etc.
          The government's main objective is to provide these public facilities
          to citizens and businesses to manage social and economic activities
          more smoothly.ANS :-Public facilities are services provided by the
          government to its citizens. Some of the important public facilities
          include infrastructure, sanitation, public transport, health care,
          water, etc. Read more.....
        </p>
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-12">
            <div className="d-flex align-items-center pt-3">
              <div
                className="like d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => handleOnLike(blogId)}
              >
                {likes.includes(localStorage.getItem("userId")) ? (
                  <i className="fa fa-solid fa-heart"></i>
                ) : (
                  <i className="bi bi-heart"></i>
                )}{" "}
                {newLike === false
                  ? likesCount + " " + "Like"
                  : blogLike.length + " " + "Like"}
              </div>
              <div
                className="comment d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/blog/blog-detail/${blogId}`)}
              >
                <i className="bi bi-chat"></i>
                {comments.length + " "}
                Comments
              </div>
            </div>
          </div>
          
          <div className="col-md-6 col-sm-12 pt-3">
            <div className="row align-items-center">
              <div className="col-md-7 col-sm-12">
                <p className="blog-gmail m-0">
                  BHUSHANPUNGLIYA@GMAIL.COM &nbsp; &nbsp;
                </p>
              </div>
              <div className="col-md-5 col-sm-12">
                <p className="blog-date m-0">
                  &nbsp;&nbsp; {moment(date).format("DD MMMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* {commentBox && (
        <div className="comment-boxes">
          <div className="d-flex align-items-center">
            <img src={Img} alt="" className="comment-profile" />
            <input
              className="w-100 mx-3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add your comment....."
            />
            <button
              className="blog-comment-btn"
              onClick={() => handleOnComment(blogId)}
            >
              Comment
            </button>
          </div>
        </div>
      )} */}
    </>
  );
}

export default BlogCard;
