import React, { useState } from "react";
import rightImg from "../../assets/right-img.png";
import { Rating } from "@mui/material";

function RightCard() {
    const [value, setValue] = useState(2);
  return (
    <>
      <div className="right-card">
        <div className="right-img">
          <img src={rightImg} alt="" />
        </div>
        <div className="right-card-content">
          <h2 className="right-card-title">
            SAGE University the best university in Indore and Central India.
          </h2>
          <div className="d-flex">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <p className="m-0">106 rating</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default RightCard;
