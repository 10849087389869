import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import bookCustomer from "../assets/book-customer.png";
import star from "../assets/star.svg";
import RightCustomerCard from "../components/book-review/RightCustomerCard";
import SidebarContext from "../context/SidebarContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";
import { capitalizeParagraph } from "../common/toUpperCase";

function RnWans() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [newAnswer, setNewAnswer] = useState("");
  const [clickToggle, setClickToggle] = useState(false);
  const [answer, setAnswer] = useState("");

  async function handlePostAnswer() {
    // if (loggedIn === true) {
    try {
      const body = JSON.stringify({
        answer: answer,
        questionId: content?._id,
      });
      const res = await axios({
        method: "post",
        url: `${baseUrl}/rnw/answer`,
        data: body,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res?.status == 200) {
        alert("Answer Post is Done.");
        setAnswer("");
        fetchContent();
      }
    } catch (error) {}
  }
  async function fetchContent() {
    try {
      const response = await axios.get(`${baseUrl}/rnw/${id}`);
      setContent(response?.data?.data);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  }
  useEffect(() => {
    fetchContent();
  }, [id]);

  const handleUpdateAnswer = async (id) => {
    if (newAnswer === "") {
      return alert("Please Enter Answer.");
    }
    try {
      const res = await axios.put(
        `${baseUrl}/rnw/answer`,
        {
          answerId: id,
          newAnswer,
          questionId: content?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res?.status === 200) {
        setNewAnswer("");
        alert("Answer Updated is Done.");
        fetchContent();
      }
    } catch (error) {
      console.error("Error updating answer:", error);
    }
    setClickToggle(false);
  };

  const handleDeleteAnswer = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/rnw/answer`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          answerId: id,
          questionId: content?._id,
        },
      });
      if (res?.status === 200) {
        alert("Answer Deleted is Done.");
        fetchContent();
      }
    } catch (error) {
      console.error("Error deleting answer:", error);
    }
    setClickToggle(false);
  };

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name m-0" onClick={() => navigate("/product")}>
              &nbsp;Book /
            </p>
            <p className="path-name-bold m-0">&nbsp; Book Review</p>
          </div>
          {/* <div className="d-flex">
            <img
              src={content?.contentId?.displayImage || bookCustomer}
              alt=""
              width={"100px"}
              height={"100px"}
            />
            <div className="review-customer-box">
              <h1 className="book-customer-title">
                {content?.contentId?.heading}
              </h1>
              <h5 className="book-customer-text mb-2">
                by {content?.contentId?.author},
              </h5>
              </div>
            </div> */}
            <br/>
          <div className="d-flex align-items-center">
            <h5>
              <strong>Q.</strong> {capitalizeParagraph(content?.question)}
            </h5>
          </div>
          <br />
          {content?.answers?.map((answer, index) => (
            // <div key={index} className="d-flex align-items-center">
            //   <p><strong>A. - </strong>{answer.answer}</p>
            //   {answer.userId._id === localStorage.getItem("userId") && (
            //     <div>
            //       <button onClick={() => handleUpdateAnswer(answer._id)}>Update</button>
            //       <button onClick={() => handleDeleteAnswer(answer._id)}>Delete</button>
            //     </div>
            //   )}
            // </div>
            <div className="review-box">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  {/* <img
                    src={answer?.userId?.profileImage}
                    alt=""
                    width={"50px"}
                  /> */}
                  <div>
                    <h3 className="review-card-title">{capitalizeParagraph(answer?.answer)}</h3>
                  </div>
                </div>
                {clickToggle == answer?._id &&
                  answer.userId._id === localStorage.getItem("userId") && (
                    <div>
                      <input
                        type="text"
                        placeholder="Update Answer Text"
                        value={newAnswer}
                        onChange={(e) => setNewAnswer(e.target.value)}
                      />
                      <button
                        className="delete-btn"
                        onClick={() => handleUpdateAnswer(answer._id)}
                      >
                        Update
                      </button>{" "}
                      &nbsp;
                      <button
                        className="delete-btn"
                        onClick={() => handleDeleteAnswer(answer._id)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                {answer.userId._id === localStorage.getItem("userId") && (
                  <button
                    className="three-dot-icon"
                    onClick={() =>
                      setClickToggle((prevState) =>
                        prevState === answer._id ? false : answer._id
                      )
                    }
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </button>
                )}
              </div>
            </div>
          ))}
          <textarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Your answer..."
            className="youranswer"
          ></textarea>
          <br/>
          {/* Button to post the answer */}
          <button onClick={handlePostAnswer} className="PostAnswer">Post Answer</button>
        </div>
        <div className="right-box">
        <br/>
          <h2 className="right-title">Products related to this item</h2>
          {/* <div className="right-customer-box">
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
          </div> */}
        </div>
      </section>
    </>
  );
}

export default RnWans;
