import React, { useState } from "react";
import QuotesContext from "./QuotesContext";


const QuotesState = (props) => {
    const [quotesComment, setQuotesComment] = useState([]);
    const [quotesLike, setQuotesLike] = useState([]);
    return(
        <QuotesContext.Provider value={{quotesComment, setQuotesComment, quotesLike, setQuotesLike}}>
            {props.children}
        </QuotesContext.Provider>
    )
}

export default QuotesState;