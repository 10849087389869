import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import { toast } from "react-toastify";
import AvatarEditor from "react-avatar-editor";

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

function EditProfile() {
  const side = useContext(SidebarContext);
  const params = useParams();
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  const pathName = useLocation();

  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const editorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    console.log("funciton is calling");
  };
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState({});
  const [toggleAdd, setToggleAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [debouncedName, setDebouncedName] = useState("");
  const [selectedAdd, setSelectedAdd] = useState({
    address: "",
    pincode: "",
  });
  const [newAddress, setNewAddress] = useState({
    address: "",
    pincode: "",
  });
  const [nickNameStatus, setNickNameStatus] = useState({
    available: null,
    message: "",
  });

  const handleSelect = (data) => {
    setSelectedAdd(data);
  };

  useEffect(() => {
    // API call whenever debouncedName changes
    if (debouncedName) {
      checkNicknameAvailability(debouncedName);
    }
  }, [debouncedName]);

  const checkNicknameAvailability = async (nickname) => {
    try {
      const res = await axios.post(`${baseUrl}/auth/check-nickname`, {
        nickName: nickname,
      });
      setNickNameStatus({
        available: res.data.data.available,
        message: res.data.message,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Debounce function to delay the API call
    const handler = setTimeout(() => {
      setDebouncedName(nickName);
    }, 1000);

    // Cleanup function to clear the timeout if name changes before 3 seconds
    return () => {
      clearTimeout(handler);
    };
  }, [nickName]);

  // const debouncedCheckNicknameAvailability = debounce(
  //   checkNicknameAvailability,
  //   2000
  // );

  // useEffect(() => {
  //   if (nickName) {
  //     debouncedCheckNicknameAvailability(nickName);
  //   }
  // }, [nickName]);

  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    const res = await axios({
      method: "get",
      url: baseUrl + `/user/getauser/${userId}`,
    });
    setUserData(res?.data?.data);
    setSelectedAdd(res?.data?.data?.shippingAddress[0]);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleAddAddress = async () => {
    if (newAddress?.address !== "" && newAddress?.pincode !== "") {
      const userId = localStorage.getItem("userId");
      const res = await axios.patch(
        `${baseUrl}/user/address/add/${userId}`,
        newAddress
      );

      console.log(res);
      if (res?.status == 200) {
        toast.success("Address Added Successfully.");
        setToggleAdd(false);
        setNewAddress({
          address: "",
          pincode: "",
        });
        getUserData();
      } else {
        toast.error("Address Added Failed.");
        setToggleAdd(false);
      }
    }
  };

  const handleDelete = async (id) => {
    const userId = localStorage.getItem("userId");
    const res = await axios.patch(`${baseUrl}/user/address/delete/${userId}`, {
      addressId: id,
    });

    console.log(res);
    if (res?.status == 200) {
      toast.success("Address deleted Successfully.");
      getUserData();
    } else {
      toast.error("Address deleted Failed.");
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setName(userData.name);
      setNickName(userData.nickName);
      setCity(userData.city || "");
      setState(userData.state || "");
      setCountry(userData.country || "");
      if (userData.profileImage) {
        setPreview(userData.profileImage); // Assuming image URL is stored in localStorage
      }
      setUserData(userData);
    }
  }, []);

  const fileChangedHandler = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  function handleOnDelete() {
    setSelectedFile(undefined);
  }

  function handleOnLogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userId");
    navigate("/login");
  }

  async function handleEditProfile() {
    const formData = new FormData();
    formData.append("nickName", nickName || "");
    formData.append("name", name || "");
    formData.append("city", city || "");
    formData.append("state", state || "");
    formData.append("country", country || "");
    // if (selectedFile) {
    //   // formData.append("image", selectedFile || "");
    //   if (editorRef.current) {
    //     const canvas = editorRef.current.getImageScaledToCanvas();
    //     canvas.toBlob(async (blob) => {
    //       if (blob) {
    //         const file = new File([blob], "profile-image.jpg", {type: "image/jpeg",});
    //         formData.append("image", file);
    //       }else{
    //         toast.error("Something Went Wrong.")
    //       }
    //     });
    //   }
    // }
    if (selectedFile && editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], "profile-image.jpg", {
            type: "image/jpeg",
          });
          formData.append("image", file);

          try {
            const res = await axios.put(
              `${baseUrl}/user/updateprofile/${params.profileId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            );

            if (res?.status === 200) {
              localStorage.setItem("userData", JSON.stringify(res?.data?.data));
              navigate("/profile");
            }
          } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.message || "An error occurred.");
          }
        }
      }, "image/jpeg");
    }
    return;
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <p className="path-name">Home / Profile / <b>{name}</b></p>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="profile-title">Edit My Profile</h2>
            <button className="profile-btn" onClick={() => handleOnLogOut()}>
              Log Out
            </button>
          </div>
        <br/>

          <div
            style={{
              backgroundColor: "#FAFAFA",
              padding: "15px",
              marginBottom: "20px",
            }}
          >
            <div className="file-upload">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column">
                  {selectedFile && (
                    <button
                      onClick={() => handleOnDelete()}
                      className="btn btn-danger mb-3"
                    >
                      Delete
                    </button>
                  )}
                  {!selectedFile && (
                    <input
                      type="file"
                      hidden
                      id="file"
                      inputprops={{ accept: "image/*" }}
                      name="file"
                      onChange={fileChangedHandler}
                    />
                  )}
                  {!selectedFile && (
                    <label htmlFor="file" className="file-plush-btn"></label>
                  )}
                </div>
                {selectedFile && (
                  <div
                    className="profile-image-container"
                    style={{ marginLeft: "20px" }}
                  >
                    <AvatarEditor
                      ref={editorRef}
                      image={selectedFile}
                      width={250}
                      height={250}
                      border={50}
                      borderRadius={125}
                      scale={1.2}
                    />
                  </div>
                )}
                <div className="preview-img h-100">
                  <img
                    src={preview}
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <h6 className="d-flex align-items-center profile-name p-0 m-0">
                Edit Name
              </h6>
              <input
                type="text"
                className="step-one-input"
                value={name}
                placeholder="Enter name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <h6 className="d-flex align-items-center profile-name p-0 m-0">
                Edit Nickname
              </h6>
              <input
                type="text"
                className="step-one-input"
                value={nickName}
                placeholder="Enter nickname"
                onChange={(e) => setNickName(e.target.value)}
              />
              {nickNameStatus.available !== null && (
                <span
                  style={{
                    color: nickNameStatus.available ? "green" : "red",
                  }}
                >
                  {nickNameStatus.message}
                </span>
              )}
            </div>
            <div className="d-flex flex-column">
              <h6 className="d-flex align-items-center profile-name p-0 m-0">
                Edit City
              </h6>
              <input
                type="text"
                className="step-one-input"
                value={city}
                placeholder="Enter city"
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <h6 className="d-flex align-items-center profile-name p-0 m-0">
                Edit State
              </h6>
              <input
                type="text"
                className="step-one-input"
                value={state}
                placeholder="Enter state"
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <h6 className="d-flex align-items-center profile-name p-0 m-0">
                Edit Country
              </h6>
              <input
                type="text"
                className="step-one-input"
                value={country}
                placeholder="Enter country"
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <button className="edit-profile-btn" onClick={handleEditProfile}>
              Update
            </button>
            <br />
          </div>
          <br />
          <button className="edit-profile-btn" onClick={handleOpen}>
            Edit Address
          </button>
        </div>
        <div className="right-box">
        <br/>

          <h2 className="right-title text-center">Member Details</h2>
          {/* Remaining code */}
        </div>
      </section>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          "& .MuiPaper-root": {
            padding: "24px",
            maxWidth: "600px",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          },
          "& .MuiTypography-root": {
            fontFamily: "'Cinzel', serif",
            fontWeight: 700,
          },
          "& .address-container": {
            marginBottom: "16px",
            padding: "12px",
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
          },
          "& .address-text": {
            fontWeight: 500,
            marginBottom: "8px",
          },
          "& .delete-button": {
            marginLeft: "8px",
          },
        }}
      >
        <Box width={"500px"}>
          <Typography id="modal-title" variant="h6" component="div">
            Add and Delete Address
          </Typography>
          <br />
          {userData?.shippingAddress?.map((address) => (
            <div key={address._id} className="address-container">
              <div>
                <input
                  type="radio"
                  name="addressSelection"
                  checked={selectedAdd._id === address._id}
                  onChange={() => handleSelect(address)}
                />
                <Typography className="address-text">{`Address: ${address.address}`}</Typography>
                <Typography className="address-text">{`Pincode: ${address.pincode}`}</Typography>
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(address._id)}
                  className="delete-button"
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
          <br />
          {toggleAdd ? (
            <div>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                value={newAddress?.address}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, address: e.target.value })
                }
                style={{ marginBottom: "10px" }}
              />
              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                value={newAddress?.pincode}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, pincode: e.target.value })
                }
                style={{ marginBottom: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAddress}
              >
                Submit
              </Button>
            </div>
          ) : (
            <div className="add-address-button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setToggleAdd(true)}
              >
                Add Address
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </div>
          )}
        </Box>
      </Dialog>
    </>
  );
}

export default EditProfile;
