import React, { useContext } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import BookReviewCard from "../components/book-review/BookReviewCard";
import star from "../assets/star.svg";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
import { useLocation } from "react-router-dom";

function ReviewPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const pathName = useLocation();

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <p className="path-name">{pathName.pathname}</p>
          <div className="row gy-4">
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
              <BookReviewCard />
          </div>
        </div>
        <div className="right-box">
          <h2 className="right-title">Recent book review</h2>
          <div className="recent-box">
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p>
                </div>
              </div>
              <p className="m-0 review-rating-text">5.0 Rating</p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p>
                </div>
              </div>
              <p className="m-0 review-rating-text">5.0 Rating</p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p>
                </div>
              </div>
              <p className="m-0 review-rating-text">5.0 Rating</p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p>
                </div>
              </div>
              <p className="m-0 review-rating-text">5.0 Rating</p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p>
                </div>
              </div>
              <p className="m-0 review-rating-text">5.0 Rating</p>
            </div>
          </div>
          <h2 className="right-title text-center mt-3">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
    </>
  );
}

export default ReviewPage;
