import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import adImg from "../assets/ad-img.png";
import TopWriter from "../components/answer/TopWriter";
import RecentWriter from "../components/answer/RecentWriter";
import BlogCard from "../components/blog/BlogCard";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import { toast } from "react-toastify";
import BlogRightBar from "./Rightsidebar/BlogRightBar";

function BlogPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;

  const [blogs, setBlogs] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")) || null);

  const navigate = useNavigate();

  const handleCategory = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);

    const selectedCategoryData = categoryData.find(
      (cat) => cat._id === selectedCategory
    );
    setSubCategories(
      selectedCategoryData ? selectedCategoryData.subcategories : []
    );
    setSubCategory("");
  };

  const handleFollow = async (followId) => {
    const loginuserId = localStorage.getItem("userId");
    const userToken = localStorage.getItem("token");

    if (!loginuserId || !userToken) {
      toast.error("Please Login First.");
      navigate("/login");
      return;
    }
    if (followId === loginuserId) {
      return toast.error("You Can't follow yourself");
    }
    if (loginuserId === undefined || followId === undefined) {
      return toast.error("Something went Wrong");
    }
    setLoader(true);
    try {
      const res = await axios.put(`${baseUrl}/user/updateFollower/${loginuserId}`, {
        toFollowId: followId
      });

      if (res?.status === 200) {
        localStorage.setItem("userData", JSON.stringify(res?.data?.data));
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.error('Error updating follower:', error);
    }
    setLoader(false);
  }



  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  useEffect(() => {
    async function getBlogs() {
      setLoader(true);
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/content/getallcontent/type/Blogs`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setBlogs(res.data.data);
      } catch (e) {
        console.error(e);
      }
      setLoader(false);
    }
    getBlogs();
  }, []);

  async function getCategory(type = "Blogs") {
    setLoader(true);
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/category/all?contentType=blogs`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.status === 200) {
        setCategoryData(res.data.data);
      }
    } catch (e) {
      console.error(e);
    }
    setLoader(false);
  }

  useEffect(() => {
    getCategory();
  }, []);

  let filteredBlogs = blogs;
  if (category !== "") {
    filteredBlogs = filteredBlogs.filter((blog) => blog.category === category);
    if (subCategory !== "") {
      filteredBlogs = filteredBlogs.filter(
        (blog) => blog.sub_category === subCategory
      );
    }
  }

  let allBlogs = null;
  if (filteredBlogs !== null) {
    if (filteredBlogs.length === 0) {
      allBlogs = <p>No blogs available</p>;
    } else {
      allBlogs = filteredBlogs.map((element, index) => (
        <div className="newblogcards" key={index}>

          <BlogCard
            author={element.nickName}
            blogId={element._id}
            title={element.heading}
            description={element.description}
            date={element.createdAt}
            likes={element.likes}
            likesCount={element.likes.length}
            comments={element.commentArray}
            like={element}
            userData={userData}
            handleFollow={handleFollow}
          />
        </div>
      ));
    }
  } else {
    allBlogs = <p>Loading...</p>;
  }

  let topBlogs = null;
  let recentBlogs = null;

  if (blogs !== null) {
    const shuffledBlogs = blogs.sort(() => Math.random() - 0.5);
    const randomTopBlogs = shuffledBlogs.slice(0, 3);
    topBlogs = randomTopBlogs.map((blog, index) => (
      <TopWriter key={index} blog={blog} />
    ));

    const sortedRecentBlogs = blogs.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    recentBlogs = sortedRecentBlogs
      .slice(0, 4)
      .map((blog, index) => <RecentWriter key={index} blog={blog} />);
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <div className="n-e-w path-box">
           <div className="d-flex">
           <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name-bold m-0">Blog</p>
           </div>

         <div className="d-flex gap-3">
         <select onChange={handleCategory} className="form-select  ">
            <option value="">All Category</option>
            {categoryData &&
              categoryData.length > 0 &&
              categoryData.map((category, index) => (
                <option
                  value={category._id}
                  style={{ textTransform: "uppercase" }}
                  key={index}
                  className="blogtitle"
                >
                  {category.categoryName}
                </option>
              ))}
          </select>
          {subCategories.length > 0 && (
            <select
              onChange={handleSubCategory}
              className="form-select "
            >
              <option value="">All Subcategory</option>
              {subCategories.map((subCat, index) => (
                <option value={subCat} key={index}>
                  {subCat}
                </option>
              ))}
            </select>
          )}
         </div>
          </div> */}
        <br/>
          {allBlogs}
        </div>
        <BlogRightBar />
      </section>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default BlogPage;
