import React from 'react'
import Img from '../../assets/recent-writer.png'
import { useNavigate } from 'react-router-dom';

function RecentQouteWriter({blog}) {
  const navigate = useNavigate()
  console.log(blog);
  return (
    <>
    <div className="d-flex align-items-center recent-writer-box" onClick={()=>navigate(`/quotes/quotes-detail/${blog?._id}`)}>
        <div><img src={blog?.displayImage || Img} alt="" width={50} /></div>
        <div><h2 className='recent-writer-title'>{blog?.author}</h2></div>
    </div>
    </>
  )
}

export default RecentQouteWriter