import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import profile from "../assets/Ellipse 50.png";
import blogImg from "../assets/question-card-img.png";
import star from "../assets/star.svg";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import flag from "../assets/flag-in.png";
import logoProfile from "../assets/question-card-img.png";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";

function ProfilePage() {
  const side = useContext(SidebarContext);
  const userId = localStorage.getItem("userId");
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  const pathName = useLocation();

  const [userData, setUserData] = useState(null);

  function handleOnLogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userData");
    navigate("/login");
  }

  async function getUser() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/user/getauser/${userId}`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(res);
      setUserData(res?.data?.data);
    } catch (e) {
      // localStorage.clear();
      navigate("/login");
    }
  }
  useEffect(() => {
    getUser();
  }, []);
  function handleOnEdit() {
    navigate(`/edit-profile/${userId}`);
  }

  // if (userData === null) {
  //   return <></>;
  // }
  console.log(userData);

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <p className="path-name">{pathName?.pathname}</p>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="profile-title">My Profile</h2>
            <button className="profile-btn" onClick={handleOnLogOut}>
              Log Out
            </button>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "#FAFAFA",
              padding: "15px",
              marginBottom: "20px",
            }}
          > 
            <img src={userData?.profileImage || profile} alt="" width={"60px"} />
            <h6 className="d-flex align-items-center profile-name m-0">
              {userData?.nickName}
              <i className="bi bi-pencil-square" onClick={handleOnEdit}></i>
            </h6>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <h2 className="profile-title m-0">Blogs</h2>
            <button className="profile-see-btn">See All</button>
          </div>
          <div className="profile-blog-box">
            <div className="d-flex align-items-center mb-3">
              <img src={blogImg} alt="" />
              <div className="ps-3">
                <h6 className="profile-blog-title m-0">Chetan Goyal</h6>
                <p className="profile-blog-country m-0">India </p>
              </div>
            </div>
            <p className="profile-blog-text">
              I wanted to hire the best and after looking at several other
              companies, I knew Jacob was the perfect guy for the job. He is a
              true professional.I wanted to hire the best and after looking at
              several other companies, I knew Jacob was the perfect guy for the
              job. He is a true professional.
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center">
                <div className="d-flex align-items-center me-4">
                  <i className="bi bi-heart me-2"></i> Like
                </div>
                <div className="d-flex align-items-center book-review-comment">
                  <i className="bi bi-chat me-2"></i>
                  Comment
                </div>
              </div>
              <p className="blog-gmail m-0">grajput081@gmail.com</p>
              <p className="blog-date m-0">31 MAY 2023</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <h2 className="profile-title m-0">Quotes</h2>
            <button className="profile-see-btn">See All</button>
          </div>
          <div className="profile-blog-box">
            <div className="d-flex align-items-center mb-3">
              <img src={blogImg} alt="" />
              <div className="ps-3">
                <h6 className="profile-blog-title m-0">Chetan Goyal</h6>
                <p className="profile-blog-country m-0">India </p>
              </div>
            </div>
            <p className="profile-blog-text text-center">
              “You can get everything in life you want if you will just help
              enough other people get what they want.”
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center">
                <div className="d-flex align-items-center me-4">
                  <i className="bi bi-heart me-2"></i> Like
                </div>
                <div className="d-flex align-items-center book-review-comment">
                  <i className="bi bi-chat me-2"></i>
                  Comment
                </div>
              </div>
              <p className="blog-gmail d-flex align-items-center m-0">
                <i className="bi bi-calendar2-week pe-3"></i>1/06/2023
              </p>
            </div>
          </div>
        </div>
        <div className="right-box">
        <br/>

          <h2 className="right-title text-center">Member Details</h2>
          <div className="follow-profile">
            <div className="d-flex justify-content-between mb-2 align-items-center">
              <div className="d-flex align-items-center">
                <img src={logoProfile} alt="" />
                <div className="ms-3">
                  <h6 className="all-card-title">Chetan Goyal</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex justify-content-between align-items-center m-0">
                      Rating
                      <img src={star} alt="" />
                      <p className="m-0">4.5</p>
                    </p>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                      <p className="m-0">Following</p>
                      <p className="m-0">55</p>
                    </div>
                  </div>
                  <p className="country-name m-0">
                    India <img src={flag} alt="" />{" "}
                    <button className="follow-btn ms-3">Follow</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Blogs</p>
              <p className="m-0">5</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Book Review</p>
              <p className="m-0">1</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Quotes</p>
              <p className="m-0">2</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Q&A</p>
              <p className="m-0">5</p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Recent book review</h2>
          <div className="top-member-card">
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
    </>
  );
}

export default ProfilePage;
