import React, { useState } from "react";
import ReaderContext from "./ReaderContext";


const ReaderState = (props) => {
    const [readerComment, setReaderComment] = useState([]);
    const [readerLike, setReaderLike] = useState([]);
    return(
        <ReaderContext.Provider value={{readerComment, setReaderComment, readerLike, setReaderLike}}>
            {props.children}
        </ReaderContext.Provider>
    )
}

export default ReaderState;