import React, { useState } from "react";
import BookContext from "./BookContext";


const BookState = (props) => {
    const [bookComment, setBookComment] = useState([]);
    const [bookLike, setBookLike] = useState([]);
    return(
        <BookContext.Provider value={{bookComment, setBookComment, bookLike, setBookLike}}>
            {props.children}
        </BookContext.Provider>
    )
}

export default BookState;