import React, { useContext } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import Img from "../assets/question-card-img.png";
import Img1 from "../assets/profile-icon1.png";
import Img2 from "../assets/profile-icon2.jpg";
import Img3 from "../assets/answer-icon.jpg";
import adImg from "../assets/ad-img.png";
import TopWriter from "../components/answer/TopWriter";
import RecentWriter from "../components/answer/RecentWriter";
import SidebarContext from "../context/SidebarContext";
import { useLocation } from "react-router-dom";

function AnswerPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const pathName = useLocation();

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        {/* <div className="row"> */}
        {/* <div className="col-8"> */}
        <div className="center-box">
          <p className="path-name">{pathName.pathname}</p>
          <div className="answer-box">
            <div className="d-flex">
              <div>
                <img src={Img} alt="" />
              </div>
              <div className="d-flex flex-column ps-3">
                <div className="d-flex align-items-center">
                  <p className="question-title m-0">Chetan Goyal</p>
                  <ul className="m-0">
                    <li>
                      <a href="">Follow</a>
                    </li>
                  </ul>
                </div>
                <div className="question-time">
                  <i className="bi bi-clock"></i> 5 hrs
                </div>
              </div>
            </div>
            <h1 className="answer-card-title">
              What do you mean by public facilities?
            </h1>
            <div className="d-flex align-items-center pt-3">
              <div className="like d-flex align-items-center">
                <i className="bi bi-heart"></i> Like
              </div>
              <div className="comment d-flex align-items-center">
                <i className="bi bi-chat"></i>2 Comment
              </div>
            </div>
            <div className="comment-card">
              <div className="d-flex align-items-center">
                <img src={Img1} className="profile-img" alt="" />
                <div className="comment-content">
                  <i className="bi bi-chevron-left"></i>
                  <h6 className="m-0 comment-title">
                    Chetan Goyal{" "}
                    <span className="question-time">
                      <i className="bi bi-clock"></i> 5 hrs
                    </span>
                  </h6>
                  <p className="m-0 comment-text">
                    Public facilities are services provided by the government to
                    its citizens.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-card">
              <div className="d-flex align-items-center">
                <img src={Img2} className="profile-img" alt="" />
                <div className="comment-content">
                  <i className="bi bi-chevron-left"></i>
                  <h6 className="m-0 comment-title">
                    Chetan Goyal{" "}
                    <span className="question-time">
                      <i className="bi bi-clock"></i> 5 hrs
                    </span>
                  </h6>
                  <p className="m-0 comment-text">
                    Public facilities are services provided by the government to
                    its citizens.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="comment-answer">
              <div className="d-flex align-items-center">
                <img src={Img3} alt="" className="profile-img" />
                <div className="d-flex w-100">
                  <input
                    type="text"
                    placeholder="Add your Answer"
                    className="comment-content"
                  />
                  <button className="send-btn">Comment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="col-4"> */}
        <div className="right-box">
          <h2 className="right-title">Top Writers</h2>
          <div className="suggest-box">
            <TopWriter />
            <TopWriter />
            <TopWriter />
            <TopWriter />
          </div>
          <h2 className="recent-title">Recent Writers</h2>
          <div className="suggest-box">
            <RecentWriter />
            <RecentWriter />
            <RecentWriter />
          </div>
          <div className="text-center">
            <h2 className="recent-title">Place for ad</h2>
            <img src={adImg} alt="" />
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
}

export default AnswerPage;
