import React, { useEffect, useState } from "react";
import axios from "axios";
import "./modal.css";
import { baseUrl } from "../../context/baseUrl";

function CollageAddQuestion({ setIsOpen, contentId, getQnAData }) {
  const [question, setQuestion] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    // Enable/disable button based on the length of the question
    setIsDisabled(question.length === 0);
  }, [question]);

  const handleAddQuestion = async () => {
    try {
      // Send the question data to the backend
      await axios.post(`${baseUrl}/qna`, { question, contentId }, {
        headers:{
          Authorization:`Bearer ${localStorage.getItem("token")}`
        }
      });
      getQnAData(contentId)
      // Close the modal after successfully adding the question
      setIsOpen(false);
    } catch (error) {
      console.error("Error adding question:", error);
      // Handle error if needed
    }
  };

  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="blog-modal">
          <div className="modalHeader">
            <h5 className="heading">Add Question</h5>
          </div>
          <div className="modal-input-box">
            <input
              type="text"
              className="modal-input"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Add question"
            />
          </div>
          <div className="d-flex justify-content-end">
            <button className="cancel-btn" onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button
              className={isDisabled ? "disabled-btn" : "add-question"}
              disabled={isDisabled}
              onClick={handleAddQuestion}
            >
              Add Question
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollageAddQuestion;
