import React, { useContext, useState } from "react";
import Header from "../components/header/header";
import { Slider } from "@mui/material";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import CardLogo from "../assets/review-img.png";
import customerCardImg from "../assets/customer-card-img.png";
import Rating from "../components/Rating";
import SidebarContext from "../context/SidebarContext";
import { useLocation } from "react-router-dom";

function CustomerPage() {
  const [fiveStar, setFiveStar] = useState(70);
  const [fourStar, setFourStar] = useState(21);
  const [threeStar, setThreeStar] = useState(5);
  const [twoStar, setTwoStar] = useState(1);
  const [oneStar, setOneStar] = useState(2);

  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const pathName = useLocation();
  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="row">
          <div className="col-8">
            <div className="center-box">
            <p className="path-name">{pathName.pathname}</p>
              <h2 className="customer-title">Customer Review</h2>
              <div className="customer-full-review">
                <div className="review-box">
                  <div className="d-flex align-items-center">
                    <img src={CardLogo} alt="" />
                    <div>
                      <h3 className="review-card-title">AKASH SINGH RAJAWAT</h3>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Rating />
                    <div>
                      <p className="review-card-text m-0">Good Colleges</p>
                    </div>
                  </div>
                  <p className="review-card-text m-0">
                    Best education of indore city .
                  </p>
                  <p className="review-card-text-last m-0">
                    Reviewed in India 🇮🇳 on 1 May 2023
                  </p>
                  <ul className="customer-review-text">
                    <li>
                      Perfect facultiesamazing facilitiesbest student teacher
                      interactionbest acadamic infrastructuresbest study
                      groupamazing education systemcommunity tie-uponline
                      learning plateformbusiness mousamazing location neat to
                      naturepeaceful place
                    </li>
                    <li>
                      Yes our college provide placement and job opportunity they
                      send some of the best student all around the country and
                      world too that's the advantage of being in sage university
                      the faculty suggest you to the companies
                    </li>
                    <li>
                      I didn't get any internship yet but my seniors got and
                      they told me that that was a great experience for them to
                      get an internship in those companies they wanted.
                    </li>
                    <li>
                      Difficulties of college is knowledgeable and they can
                      teach you very good knowledge and the education of there
                      is also good.
                    </li>
                    <li>
                      The hostel service is good and there is a separate hostel
                      in our college for girls and boys hostel facilities are
                      good and affordable not so costly but facilities are good
                      and affordable and also good.
                    </li>
                    <li>
                      Android and apple labs are also here with air-conditioned
                      classes and best staff here we have spefcific sports
                      periods in which we can play a no.
                    </li>
                    <h6 className="customer-review-title">
                      These insights are automatically extracted from student
                      reviews
                    </h6>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="right-box">
              <h2 className="right-title">Collage review</h2>
              <div className="suggest-box">
                <div className="customer-card">
                  <div>
                    <img src={customerCardImg} alt="" />
                  </div>
                  <div>
                    <h6 className="customer-card-title">
                      SAGE University the best university in Indore
                    </h6>
                  </div>
                </div>
                <div className="d-flex">
                  <Rating />
                  <p className="m-0">5 out of 5</p>
                </div>
                <p className="customer-global-rating">49,020 global ratings</p>
                <div className="d-flex align-items-center five-star-rating">
                  <div className="star-num">
                    <span>5 star</span>
                  </div>
                  <Slider
                    className="rangeSlider"
                    value={fiveStar}
                    defaultValue={70}
                    onChange={(e) => setFiveStar(e.target.value)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <div className="star-per">
                    <span>{fiveStar + " %"}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center five-star-rating">
                  <div className="star-num">
                    <span>4 star</span>
                  </div>
                  <Slider
                    className="rangeSlider"
                    value={fourStar}
                    defaultValue={21}
                    onChange={(e) => setFourStar(e.target.value)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <div className="star-per">
                    <span>{fourStar + " %"}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center five-star-rating">
                  <div className="star-num">
                    <span>3 star</span>
                  </div>
                  <Slider
                    className="rangeSlider"
                    value={threeStar}
                    defaultValue={5}
                    onChange={(e) => setThreeStar(e.target.value)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <div className="star-per">
                    <span>{threeStar + " %"}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center five-star-rating">
                  <div className="star-num">
                    <span>2 star</span>
                  </div>
                  <Slider
                    className="rangeSlider"
                    value={twoStar}
                    defaultValue={1}
                    onChange={(e) => setTwoStar(e.target.value)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <div className="star-per">
                    <span>{twoStar + " %"}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center five-star-rating">
                  <div className="star-num">
                    <span>1 star</span>
                  </div>
                  <Slider
                    className="rangeSlider"
                    value={oneStar}
                    defaultValue={2}
                    onChange={(e) => setOneStar(e.target.value)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                  <div className="star-per">
                    <span>{oneStar + " %"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomerPage;
