import React from "react";
import Header from "../components/header/header";
import callIcon from "../assets/call-icon.svg";
import mailIcon from "../assets/mail-icon.svg";

function ContactUs() {
  return (
    <>
      <Header />
      <section className="contact-section">
        <div className="text-center">
          <h2 className="contact-title">Contact Us</h2>
        </div>
        <div className="contact-box">
          <div className="address-box">
            <h6 className="address-title">Customer Care</h6>
            <div
              className="d-flex flex-column"
              style={{ gap: "8px", marginBottom: "50px" }}
            >
              <div className="d-flex align-items-center">
                <img src={callIcon} style={{ marginRight: "8px" }} alt="" />{" "}
                <p className="contact-text">+91 8743673478</p>
              </div>
              <div className="d-flex align-items-center">
                <img src={mailIcon} style={{ marginRight: "8px" }} alt="" />{" "}
                <p className="contact-text">abc@gmail.com</p>
              </div>
            </div>
            <h6 className="address-title">Office Address:</h6>
            <p className="m-0 contact-text">BOOKSONFLY, <br />
            Choice Collection, Sector 2 Market, Dhruwa, Ranchi- 834002, Jharkhand </p>
          </div>
          <div className="form-box">
            <input type="text" className="form-input" placeholder="Name:" />
            <input type="text" className="form-input" placeholder="Email address:" />
            <input type="text" className="form-input" placeholder="Subject:" />
            <textarea name="" id="" cols="30" rows="4" className="form-input" placeholder="Your Massage:"></textarea>
            <button className="send-btn">Send Message</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
