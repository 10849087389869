import React from 'react'
import star from '../assets/star.svg'
import EmptyStar from "../assets/star-empty.svg"


function Rating({ rating = 5 }) {
  const totalStars = 5;

  return (
    <ul className='rating-star'>
      {[...Array(totalStars)].map((_, index) => (
        <li key={index}>
          <img
            src={index < rating ? star : EmptyStar}
            alt=""
            style={{ height: '20px', width: '20px' }} // Ensuring same height for both images
          />
        </li>
      ))}
    </ul>
  );
  // return (
  //   <>
  //   <ul className='rating-star'>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //       <li><img src={star} alt="" /></li>
  //   </ul>
  //   </>
  // )
}

export default Rating