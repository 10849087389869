import React from 'react'
import Header from '../components/header/header'
import SignInPage from '../components/signinPage/signinPage'

function SignIn() {
  return (
    <>
    <Header />
    <SignInPage />
    </>
  )
}

export default SignIn