import React, { useEffect, useState } from "react";
import TopWriter from "../../components/answer/TopWriter";
import adImg from "../../assets/ad-img.png";
import axios from "axios";
import { baseUrl } from "../../context/baseUrl";
import RecentWriter from "../../components/answer/RecentWriter";
import TopUser from "../../components/answer/TopUser";

const BlogRightBar = () => {
  const [recent, setRecent] = useState(null);
  const [topBlog, setTopBlog] = useState(null);
  useEffect(() => {
    async function getBlogs() {
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/content/gettopcontent/type/Blogs`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        // console.log(res);
        setRecent(res?.data?.data?.recent);
        setTopBlog(res?.data?.data?.top);
      } catch (e) {
        console.error(e);
      }
    }
    getBlogs();
  }, []);

  console.log(topBlog);

  return (
    <div className="right-box">
    <br/>
      <h2 className="right-title text-center">Top Member</h2>
      <div className="suggest-box">
        {topBlog &&
          topBlog?.length > 0 &&
          topBlog.map((blog, ind) => <TopUser key={ind} blog={blog} />)}
      </div>
      <div className="suggest-box">
        <ul
          className="nav nav-tabs justify-content-around"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Top Blog
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Recent Blog
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {topBlog &&
              topBlog?.length > 0 &&
              topBlog.map((blog, ind) => <TopWriter key={ind} blog={blog} />)}
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {recent &&
              recent?.length > 0 &&
              recent.map((blog, ind) => <RecentWriter key={ind} blog={blog} />)}
          </div>
        </div>
      </div>
      <h2 className="recent-title text-center">Place for ad</h2>
      <img src={adImg} alt="" />
    </div>
  );
};

export default BlogRightBar;
