// https://blog.logrocket.com/build-rich-text-editors-react-draft-js-react-draft-wysiwyg/

// -------
import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import './App.css';

function TextEditor({ convertedContent, setConvertedContent, setFormData, formData, isEdit, flag1=false, flag2=false }) {
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
  useEffect(() => {
    if (isEdit) {
      if (flag1) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(formData.description1))
          )
        );
      } else if (flag2) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(formData.description2))
          )
        );
      } else {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(formData.description))
          )
        );
      }
    }
  }, [isEdit]);
  
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    // {draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    console.log(currentContentAsHTML, 'this is current content as html');
    setConvertedContent(currentContentAsHTML);
    if(formData){
      // if (flag1) {
      //   setFormData({ ...formData, description1: currentContentAsHTML });
      // }
      // else if (flag2) {
      //   setFormData({ ...formData, description2: currentContentAsHTML });
      // }
      // else{
        setFormData({...formData, description: currentContentAsHTML});
      // }
    }
  };

  return (
    <div style={{ borderRadius: 10, padding: '5px' }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
    </div>
  );
}

export default TextEditor;
