import React, { useEffect, useState } from "react";
import Header from "../header/header";
import flag from "../../assets/flag-in.png";
import logoProfile from "../../assets/question-card-img.png";
import star from "../../assets/star.svg";
import bookReview from "../../assets/book-review.png";
import adImg from "../../assets/ad-img.png";
import axios from "axios";
import { useNavigate, useNavigation } from "react-router-dom";
import Buy1 from "../../assets/buy-1.svg";
import like from "../../assets/Heart.png";
import liked from "../../assets/liked-btn.svg";
import { baseUrl } from "../../context/baseUrl";

function BuyCard({ data, index, handleUpdate,
}) {
  const navigate = useNavigate()
  const handleDelete = async(id)=>{
    try {
      const res = await axios.delete(`${baseUrl}/product/deleteproduct/${id}`, {
        headers:{
          authorization:`Bearer ${localStorage.getItem("token")}`
        }
      })
      console.log(res)
      if(res?.status==200){
        handleUpdate()
        alert("Product Deleted Successfully.")
      }
    } catch (error) {
      alert("delete product is Failed.")
    }
  }
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
        <div
          className="buy-card"
        >
          <img src={data?.displayImages[0]?.url||Buy1} alt="" width={"200px"} height={"280px"}  style={{marginLeft:"1rem"}}/>
          <br />
          <br />
          <h6 className="buy-card-title">{data?.productName}</h6>
          <p className="buy-card-text">{data?.description}</p>
          <h6 className="buy-card-title">Quantity {data?.quantity}</h6>
          <h6 className="buy-card-title">Sold Quantity {data?.totalSold}</h6>

          {/* <div style={{ marginLeft: "10px", display: "flex", gap: "5px" }}>
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <p
              className="m-0"
              style={{
                color: "#8C8C8C",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
              }}
            >
              106 rating
            </p>
          </div> */}
          <p className="m-0 product-price">Price Rs. {data?.price}</p>
          <p className="m-0 product-price">Web Price Rs. {data?.webPrize||""}</p>
          <button onClick={()=>navigate(`/editsell/${data?._id}`)}>Edit</button> &nbsp; &nbsp;
          <button onClick={()=>handleDelete(data?._id)}>Delete</button>
        </div>
      </div>
    </>
  );
}

function ProductList() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);
  async function getProducts() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/product/getbyuser`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setProducts(res.data.data);
    } catch (e) {}
  }
  const handleUpdate =()=>{
    getProducts()
  }
  return (
    <>
      <Header />
      <section className="hero-section">
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p className="path-name m-0">Home /</p>
            <p className="path-name-bold m-0">&nbsp; Product</p>
          </div>
          <div className="buy-container">
            <div className="row">
              {products.map((element, index) => {
                return (
                  <>
                    <BuyCard
                      index={index}
                      id={element._id}
                      data={element}
                      handleUpdate={handleUpdate}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right-box">
        <br/>
          <h2 className="right-title text-center">Member Details</h2>
          <div className="follow-profile">
            <div className="d-flex justify-content-between mb-2 align-items-center">
              <div className="d-flex align-items-center">
                <img src={logoProfile} alt="" />
                <div className="ms-3">
                  <h6 className="all-card-title">Chetan Goyal</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex justify-content-between align-items-center m-0">
                      Rating
                      <img src={star} style={{ margin: "0 10px" }} alt="" />
                      <p className="m-0">4.5</p>
                    </p>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                      <p className="mb-0 me-2">Following</p>
                      <p className="m-0">55</p>
                    </div>
                  </div>
                  <p className="country-name mb-0 mt-2">
                    India <img src={flag} alt="" />
                    <button className="follow-btn ms-3">Follow</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Blogs</p>
              <p className="m-0">5</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Book Review</p>
              <p className="m-0">1</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Quotes</p>
              <p className="m-0">2</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Q&A</p>
              <p className="m-0">5</p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Recent book review</h2>
          <div className="top-member-card">
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                  {/* <p className="m-0 d-flex">
                    <img src={star} alt="" /> <img src={star} alt="" />{" "}
                    <img src={star} alt="" /> <img src={star} alt="" />
                    <img src={star} alt="" />
                  </p> */}
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
    </>
  );
}

export default ProductList;
