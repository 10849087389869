import React from 'react'
import Header from '../components/header/header'
import LoginPage from '../components/loginpage/loginpage'

function Login() {
  return (
    <>
    <Header />
    <LoginPage />
    </>
  )
}

export default Login