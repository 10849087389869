import React, { useState } from "react";
import SidebarContext from "./SidebarContext";


const SidebarState = (props) => {
    const [LeftSideBar, setLeftSidebar] = useState(false);
    function openLeftSidebar() {
        setLeftSidebar(!LeftSideBar);
    }
    return(
        <SidebarContext.Provider value={{openLeftSidebar, LeftSideBar,setLeftSidebar}}>
            {props.children}
        </SidebarContext.Provider>
    )
}

export default SidebarState;