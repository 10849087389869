import React, { useContext } from "react";
import LeftSidebar from "../left-sidebar/LeftSidebar";
import Header from "../header/header";
import bookCustomer from "../../assets/book-customer.png";
import star from "../../assets/star.svg";
import RightCustomerCard from "../book-review/RightCustomerCard";
import SidebarContext from "../../context/SidebarContext";
import { useLocation } from "react-router-dom";

function ReviewDescription() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const pathName = useLocation();


  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
        <p className="path-name">{pathName.pathname}</p>
          <div className="d-flex">
            <img src={bookCustomer} alt="" />
            <div className="review-customer-box">
              <h2 className="book-customer-title">
                World’s Greatest Books For Personal Growth & Wealth (Set of 4
                Books): Perfect Motivational Gift Set Paperback – 1 August 2019
              </h2>
              <p className="book-customer-text mb-2">
                by (Author), Napoleon Hill (Author), Dr. Joseph Murphy (Author)
              </p>
              <div className="d-flex">
                <img src={star} alt="" /> <img src={star} alt="" />{" "}
                <img src={star} alt="" /> <img src={star} alt="" />{" "}
                <img src={star} alt="" />
                <p className="rating-total m-0">106 rating</p>
              </div>
            </div>
          </div>
          <div>
            <h4 className="product-title">Product details</h4>
            <div className="product-detail-box">
              <p className="product-detail-text m-2">
                Publisher :{" "}
                <span>
                  {" "}
                  Fingerprint! Publishing; Special Edition (1 August 2019);
                  Prakash Books India Pvt Ltd, 113A, Ansari Road, Daryaganj, New
                  Delhi-110002, +9111-23265358
                </span>
              </p>
              <p className="product-detail-text m-2">
                Language : <span>English</span>
              </p>
              <p className="product-detail-text m-2">
                Paperback : <span>900 pages</span>
              </p>
              <p className="product-detail-text m-2">
                ISBN-10 : <span> 9389432014</span>
              </p>
              <p className="product-detail-text m-2">
                ISBN-13 : <span>978-9389432015</span>
              </p>
              <p className="product-detail-text m-2">
                Reading age :{" "}
                <span>Customer suggested age: 13 years and up</span>
              </p>
              <p className="product-detail-text m-2">
                Item Weight : <span>750 g</span>
              </p>
              <p className="product-detail-text m-2">
                Dimensions : <span>20.3 x 25.4 x 4.7 cm</span>
              </p>
              <p className="product-detail-text m-2">
                Country of Origin : <span>India</span>
              </p>
              <p className="product-detail-text m-2">
                Net Quantity : <span>1 count</span>
              </p>
              <p className="product-detail-text m-2">
                Importer : <span>Prakash Books India Pvt Ltd</span>
              </p>
              <p className="product-detail-text m-2">
                Packer : <span>Prakash Books India Pvt Ltd</span>
              </p>
              <p className="product-detail-text m-2">
                Customer Reviews : <span>22,686 ratings</span>
              </p>
            </div>
          </div>
        </div>
        <div className="right-box">
          <h2 className="right-title">Products related to this item</h2>
          <div className="right-customer-box">
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewDescription;
