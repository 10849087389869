import React, { useContext, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import bookCustomer from "../assets/book-customer.png";
import star from "../assets/star.svg";
// import reviewWrite from "../assets/review-write.png";
// import CustomerReviewCard from "../components/book-review/CustomerReviewCard";
import RightCustomerCard from "../components/book-review/RightCustomerCard";
import SidebarContext from "../context/SidebarContext";
// import rightArrow from "../assets/right-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";

function ProductDetail() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  var params = useParams();
  var productId = params.id;

  const [quantity, setQuantity] = useState(1);
  let hasPrev = quantity > 1;

  async function AddToCart() {
    var raw = JSON.stringify({});
    try {
      const res = await axios({
        method: "post",
        url:
          baseUrl +
          `/cart/addproduct?productId=${productId}&type=add&quantity=${quantity}`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
    } catch (e) {}
  }

  const handleBuyNow = async()=>{
    var raw = JSON.stringify({});
    try {
      const res = await axios({
        method: "post",
        url:
          baseUrl +
          `/cart/addproduct?productId=${productId}&type=add&quantity=${quantity}`,
        data: raw,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if(res?.status ==200){
        navigate('/cart')
      }
    } catch (e) {}
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="d-flex align-items-center path-box">
            <p
              className="path-name m-0"
              onClick={() => {
                navigate("/");
              }}
            >
              Home /
            </p>
            <p className="path-name m-0" onClick={() => navigate("/product")}>
              &nbsp;Product /
            </p>
            <p className="path-name-bold m-0">&nbsp; Product Review</p>
          </div>
          <div className="d-flex">
            <img src={bookCustomer} alt="" />
            <div className="review-customer-box">
              <h2 className="book-customer-title">
                World’s Greatest Books For Personal Growth & Wealth (Set of 4
                Books): Perfect Motivational Gift Set Paperback – 1 August 2019
              </h2>
              <p className="book-customer-text mb-2">
                by (Author), Napoleon Hill (Author), Dr. Joseph Murphy (Author)
              </p>
              <div className="d-flex">
                <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" /> <img src={star} alt="" />
                <img src={star} alt="" />
                <p className="rating-total m-0">106 rating</p>
              </div>
              <div className="d-flex align-items-center pt-3">
                <p className="m-0">QUANTITY</p>
                <div className="d-flex align-items-center">
                  <button
                    className="quantity-btn"
                    disabled={!hasPrev}
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    -
                  </button>
                  <p className="m-0 px-3 bg-white text-bold">{quantity}</p>
                  <button
                    className="quantity-btn"
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="mt-3">
                <button className="add-to-cart-btn" onClick={() => AddToCart()}>
                  Add To Cart
                </button>
                <button className="buy-now-btn" onClick={()=>handleBuyNow()}>Buy Now</button>
              </div>
            </div>
          </div>
          <h2 className="review-title">Product details</h2>
          <div className="product-detail-box">
            <p className="m-0 product-box-text">
              <span className="product-box-title">Publisher : </span>{" "}
              Fingerprint! Publishing; Special Edition (1 August 2019); Prakash
              Books India Pvt Ltd, 113A, Ansari Road, Daryaganj, New
              Delhi-110002, +9111-23265358
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Language : </span>English
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Paperback : </span>900 Pages
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">ISBN-10 : </span> 9389432014
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">ISBN-13 : </span>
              978-9389432015
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Reading age : </span> Customer
              suggested age: 13 years and up
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Item Weight : </span>750 g
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Dimensions : </span>20.3 x
              25.4 x 4.7 cm
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Country of Origin : </span>{" "}
              India
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Net Quantity : </span> 1 count
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Importer : </span> Prakash
              Books India Pvt Ltd
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Packer : </span> Prakash Books
              India Pvt Ltd
            </p>
            <p className="m-0 product-box-text">
              <span className="product-box-title">Customer Reviews : </span>
              <img src={star} alt="" /> <img src={star} alt="" />
              <img src={star} alt="" /> <img src={star} alt="" />
              <img src={star} alt="" style={{ marginRight: "10px" }} />
              22,686 ratings
            </p>
          </div>
        </div>
        <div className="right-box">
          <h2 className="right-title">Products related to this item</h2>
          <div className="right-customer-box">
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
            <RightCustomerCard />
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductDetail;
