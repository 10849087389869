// ButtonLoader.js
import React from 'react';
import './ButtonLoader.css'; // CSS file for button loader styling

const ButtonLoader = () => {
  return (
    <div className="button-loader"></div>
  );
}

export default ButtonLoader;
