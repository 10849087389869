import { createHashRouter } from "react-router-dom";
import App from "../App";
import ReviewPage from "../pages/ReviewPage";
import CustomerPage from "../pages/CustomerPage";
import QuestionPage from "../pages/QuestionPage";
import AnswerPage from "../pages/AnswerPage";
import Sell from "../components/sell-page/Sell";
import AllPage from "../pages/AllPage";
import BookReview from "../pages/BookReview";
import ReviewCustomer from "../pages/ReviewCustomer";
import ReviewDescription from "../components/review-page/ReviewDescription";
import QuotesPage from "../pages/QuotesPage";
import ReaderClubPage from "../pages/ReaderClubPage";
import WriterClubPage from "../pages/WriterClubPage";
import ProfilePage from "../pages/ProfilePage";
import BlogPage from "../pages/BlogPage";
import BlogDetail from "../components/blog/BlogDetail";
import Login from "../pages/Login";
import SignIn from "../pages/SignIn";
import EditProfile from "../pages/EditProfile";
import AcadamyPage from "../pages/AcadamyPage";
import BookQuestion from "../pages/BookQuestion";
import AddContentPage from "../pages/AddContentPage";
import QuotesDetail from "../components/quotes-card/QuotesDetail";
import ReaderDetail from "../components/reader/ReaderDetail";
import WriterDetail from "../components/question/WriterDetail";
import BuyPage from "../pages/BuyPage";
import ProductDetail from "../pages/ProductDetail";
import Cart from "../pages/Cart";
import BookQ from "../pages/BookQ";
import CollageQ from "../pages/CollageQ";
import BookDetail from "../pages/BookDetail";
import BookReviews from "../pages/BookReviews";
import CollegeDetail from "../pages/CollegeDetail";
import CollegeReview from "../pages/CollegeReview";
import ContactUs from "../pages/ContactUs";
import ForgetPage from "../pages/ForgetPage";
import WishlistPage from "../pages/WishlistPage";
import ProductList from "../components/sell-page/ProductList";
import EditSell from "../components/sell-page/EditSell";
import QNAAnswer from "../pages/QNAAnswer";
import RnWans from "../pages/RnWans";
import MemberPage from "../pages/MemberPage";
import NewHome from "../pages/NewHome";
import NewPage from "../pages/NewPage";

const router = createHashRouter([
    {
        path: '/',
        element: <NewHome />
    },
    // {
    //     path: '/',
    //     element: <AllPage />
    // },
 
    {
        path: '/collage-review',
        element: <ReviewPage />
    },
    {
        path: '/colleges',
        element: <AcadamyPage />
    },
    {
        path: '/customer-review',
        element: <CustomerPage />
    },
    {
        path: '/acadamy-pages',
        element: <QuestionPage />
    },
    {
        path: '/qna-ans/:id',
        element: <QNAAnswer />
    },
    {
        path: '/rnw-ans/:id',
        element: <RnWans />
    },
    {
        path: '/book-question/:id',
        element: <BookQ />
    },
    {
        path: '/collage-question/:id',
        element: <CollageQ />
    },
    {
        path: '/book-question',
        element: <CollageQ />
    },
    {
        path: '/answer',
        element: <AnswerPage />
    },
    {
        path: '/sell',
        element: <Sell />
    },
    {
        path: '/editsell/:id',
        element: <EditSell />
    },
    {
        path: '/productlist',
        element: <ProductList />
    },
    {
        path: '/members',
        element: <AllPage />
    },
    {
        path: '/member/:id',
        element: <MemberPage />
    },
    {
        path: '/blog',
        element: <BlogPage />
    },
    {
        path: '/blog/blog-detail/:blogId',
        element: <BlogDetail />
    },
    {
        path: '/book-review',
        element: <BookReview />
    },
    {
        path: '/quotes/quotes-detail/:quotesId',
        element: <QuotesDetail />
    },
    // {
    //     path: '/book-question/:id',
    //     element: <BookQuestion />
    // },
    {
        path: '/collage-detail/:id',
        element: <CollegeDetail />
    },
    {
        path: '/product/review-customer/:id',
        element: <ProductDetail />
    },
    {
        path: '/book/book-detail/:id',
        element: <BookDetail />
    },
    {
        path: '/collage/review-customer/:id',
        element: <ReviewCustomer />
    },
    {
        path: '/book/review-customer/:id',
        element: <BookReviews />
    },
    {
        path: '/college/college-customer/:id',
        element: <CollegeReview />
    },
    {
        path: '/product',
        element: <BuyPage />
    },
    {
        path: '/wishlist',
        element: <WishlistPage />
    },
    {
        path: '/review-description',
        element: <ReviewDescription />
    },
    {
        path: '/quotes',
        element: <QuotesPage />
    },
    {
        path: '/reader-club',
        element: <ReaderClubPage />
    },
    {
        path: '/reader-club/reader-club-detail/:readerId',
        element: <ReaderDetail />
    },
    {
        path: '/add-content',
        element: <AddContentPage />
    },
    {
        path: '/cart',
        element: <Cart />
    },
    {
        path: '/writer-club',
        element: <WriterClubPage />
    },
    {
        path: '/writer-club/writer-club-detail/:writerId',
        element: <WriterDetail />
    },
    {
        path: '/profile',
        element: <App />
    },
    {
        path: '/edit-profile/:profileId',
        element: <EditProfile />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/forgot',
        element: <ForgetPage />
    },
    {
        path: '/sign-in',
        element: <SignIn />
    },
    // {
    //     path: '/my-page',
    //     element: <MyPage />
    // },
    {
        path: '/contact-us',
        element: <ContactUs />
    },

    {
        path: '/mypage',
        element: <NewPage />
    },
])


export default router;