import React, { useState } from "react";
import SellContext from "./SellContext";

const SellState = (props) => {
  const [LeftSideBar, setLeftSidebar] = useState(false);
  const [productName, setProductName] = useState("");
  const [types, setTypes] = useState("");
  const initialValue = {
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
};


  function openLeftSidebar() {
    setLeftSidebar(!LeftSideBar);
  }
  return (
    <SellContext.Provider
      value={{ openLeftSidebar, initialValue, LeftSideBar, setLeftSidebar, productName, setProductName, types, setTypes}}
    >
      {props.children}
    </SellContext.Provider>
  );
};

export default SellState;
