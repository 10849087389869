import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import QuotesCard from "../components/quotes-card/QuotesCard";
import adImg from "../assets/ad-img.png";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import Img from "../assets/question-card-img.png";
import inFlag from "../assets/flag-in.png";
import RecImg from "../assets/recent-writer.png";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../context/baseUrl";
import { toast } from "react-toastify";
import QouteRightBar from "./Rightsidebar/QouteRightBar";

function RecentWriter({quote}) {
  return (
    <>
      <div className="d-flex align-items-center recent-writer-box">
        <div>
          <img src={quote?.displayImage  || RecImg} alt="" width={50} />
        </div>
        <div>
          <h2 className="recent-writer-title">{quote?.author}</h2>
        </div>
      </div>
    </>
  );
}

function TopWriter({quote}) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between writer-box">
        <div className="d-flex">
          <div className="d-flex align-self-end">
            <img src={ quote?.displayImage || Img} alt=""  width={50}/>
          </div>
          <div className="writer-detail">
            <h2 className="m-0">{quote?.author}</h2>
            <p className="writer-flag m-0">
              India <img src={inFlag} alt="" />
            </p>
          </div>
        </div>
        <div>
          <button className="view-btn">View</button>
        </div>
      </div>
    </>
  );
}

function QuotesPage() {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [quotes, setQuotes] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [toggle, setToggle] = useState(false)
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")) || null);
  const navigate = useNavigate();

  const handleCategory = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);

    const selectedCategoryData = categoryData.find(
      (cat) => cat._id === selectedCategory
    );
    setSubCategories(
      selectedCategoryData ? selectedCategoryData.subcategories : []
    );
    setSubCategory("");
  };

  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  const handleFollow = async (followId) => {
    const loginuserId = localStorage.getItem("userId");
    const userToken = localStorage.getItem("token");

    if (!loginuserId || !userToken) {
      toast.error("Please Login First.");
      navigate("/login");
      return;
    }
    if (followId === loginuserId) {
      return toast.error("You Can't follow yourself");
    }
    if (loginuserId === undefined || followId === undefined) {
      return toast.error("Something went Wrong");
    }

    try {
      const res = await axios.put(`${baseUrl}/user/updateFollower/${loginuserId}`, {
        toFollowId: followId
      });

      if (res?.status === 200) {
        localStorage.setItem("userData", JSON.stringify(res?.data?.data));
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.error('Error updating follower:', error);
    }
  }

  async function getQuotes() {
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrl}/content/getallcontent/type/Quotes`,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setQuotes(res.data.data);
    } catch (e) {}
  }
  useEffect(() => {
    getQuotes();
  }, []);

  useEffect(() => {
    async function getCategory(type = "Quotes") {
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/category/all?contentType=quotes`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.status === 200) {
          setCategoryData(res.data.data);
        }
      } catch (e) {
        console.error(e);
      }
    }
    getCategory();
  }, []);

  let filteredQuotes = quotes;

  if (category !== "") {
    filteredQuotes = filteredQuotes.filter((blog) => blog.category === category);
    if (subCategory !== "") {
      filteredQuotes = filteredQuotes.filter(
        (blog) => blog.sub_category === subCategory
      );
    }
  }
  let allQuotes = null;

  if (filteredQuotes !== null) {
    if (filteredQuotes.length === 0) {
      allQuotes = <p>No quotes available</p>;
    } else {
      allQuotes = filteredQuotes.map((element, index) => (
        <div key={index}>
          <QuotesCard
            author={element.nickName}
            quotesId={element._id}
            title={element.heading}
            description={element.description}
            date={element.createdAt}
            likes={element.likes}
            likesCount={element.likes.length}
            comments={element.commentArray}
            like={element}
            userData={userData}
            handleFollow={handleFollow}
          />
        </div>
      ));
    }
  } else {
    allQuotes = <p>Loading...</p>;
  }

  let topQuotes = null;
  let recentQuotes = null;

  if (quotes !== null) {
    const shuffledQuotes = quotes.sort(() => Math.random() - 0.5);
    const randomTopQuotes = shuffledQuotes.slice(0, 5);
    topQuotes = randomTopQuotes.map((quote, index) => (
      <TopWriter key={index} quote={quote} />
    ));

    const sortedRecentQuotes = quotes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    recentQuotes = sortedRecentQuotes.slice(0, 5).map((quote, index) => (
      <RecentWriter key={index} quote={quote} />
    ));
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
        <div className="ct-boxes">

        <div className="n-e-w path-box">
  {/* Category Filter as Dropdown */}
  <div className="categories-container">
    <select
      className="form-select"
      onChange={(e) => handleCategory(e.target.value)}
      value={category}
    >
      <option value="">Select a Category</option>
      {categoryData &&
        categoryData.length > 0 &&
        categoryData.map((categoryItem) => (
          <option key={categoryItem._id} value={categoryItem._id}>
            {categoryItem.categoryName}
          </option>
        ))}
    </select>

    
    <select
      onChange={handleSubCategory}
      value={subCategory}
      className="form-select"
    >
      <option value="">{subCategories.length > 0 ? "All Subcategories" : "None"}</option>
      {subCategories.length > 0 ? (
        subCategories.map((subCat, index) => (
          <option value={subCat} key={index}>
            {subCat}
          </option>
        ))
      ) : (
        <option value="none" disabled>
          None
        </option>
      )}
    </select>
  </div>
</div>

<div className="n-e-w path-box">
<div className="categories-container">
         <select
      className="form-select"
     
    >
    <option value="top-rated">Top Rated</option>
    <option value="recent">Recent</option>

      
        
    </select>
    </div>
    </div>
    </div>


          <div className="row gy-4">{allQuotes}</div>
        </div>
        
       <QouteRightBar />
      </section>
    </>
  );
}

export default QuotesPage;
