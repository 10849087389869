import React from "react";
import Img from "../../assets/question-card-img.png";
import inFlag from "../../assets/flag-in.png";
import { useNavigate } from "react-router-dom";

function TopQouteWriter({blog}) {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex align-items-center justify-content-between writer-box" onClick={()=>navigate(`/quotes/quotes-detail/${blog?._id}`)}>
        <div className="d-flex">
          <div className="d-flex align-self-end" onClick={()=>navigate(`/quotes/quotes-detail/${blog?._id}`)}>
            <img src={blog?.displayImage || Img} alt=""  width={50}/>
          </div>
          <div className="writer-detail" onClick={()=>navigate(`/quotes/quotes-detail/${blog?._id}`)}>
            <h2 className="m-0">{blog?.author}</h2>
            <p className="writer-flag m-0">
              India <img src={inFlag} alt="" />
            </p>
          </div>
        </div>
        <div>
          <button className="view-btn">View</button>
        </div>
      </div>
    </>
  );
}

export default TopQouteWriter;
