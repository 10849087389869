import React, { useContext, useState } from "react";
import Img from "../../assets/question-card-img.png";
import axios from "axios";
import LoginContext from "../../context/login/LoginContext";
import { useNavigate } from "react-router-dom";
import ReaderContext from "../../context/reader/ReaderContext";
import { baseUrl } from "../../context/baseUrl";

function QuestionCard({
  author,
  description,
  date,
  likes,
  comments,
  questionId,
  likesCount,
}) {
  const login = useContext(LoginContext);
  const reader = useContext(ReaderContext);
  const { loggedIn } = login;
  const navigate = useNavigate();
  const { readerComment, setReaderComment, readerLike, setReaderLike } = reader;
  const [newComment, setNewComment] = useState(false);
  const [newLike, setNewLike] = useState(false);
  const [commentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState("");
  function toggleCommentBox() {
    setCommentBox(!commentBox);
  }
  async function handleOnComment(e) {
    e.preventDefault();
    if (loggedIn === true) {
      const id = e.target.id;
      try {
        const body = JSON.stringify({
          comment: comment,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/postcomment/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setComment("");
        setReaderComment(res.data.data.commentArray);
        setNewComment(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }
  async function handleOnLike(id) {
    if (loggedIn === true) {
      try {
        const body = JSON.stringify({
          contentId: id,
        });
        const res = await axios({
          method: "put",
          url: `${baseUrl}/comments/updatelikes/${id}`,
          data: body,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setReaderLike(res.data.data.likes);
        setNewLike(true);
      } catch (e) {}
    } else {
      navigate("/login");
    }
  }

  let allComment;
  if (newComment === false) {
    allComment = comments.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  } else {
    allComment = readerComment.map((element, index) => {
      return (
        <>
          <div className="d-flex">
            <img src={Img} alt="" className="comment-profile" />
            <div className="ms-3">
              <p className="comment-text">{element.comment}</p>
            </div>
          </div>
        </>
      );
    });
  }
  return (
    <>
      <div
        className="question-card"
        style={
          commentBox === true
            ? { marginBottom: "0px" }
            : { marginBottom: "18px" }
        }
      >
        <div className="d-flex">
          <div>
            <img src={Img} alt="" />
          </div>
          <div className="d-flex flex-column ps-3">
            <div className="d-flex align-items-center">
              <p className="question-title m-0">{author}</p>
              <ul className="m-0">
                <li>
                  <a href="">Follow</a>
                </li>
              </ul>
            </div>
            <div className="question-time">
              <i className="bi bi-clock"></i> 5 hrs
            </div>
          </div>
        </div>
        <h1 className="question-card-title">{description}</h1>
        {/* <p className="question-card-text">
          ANS :- Public facilities are services provided by the government to
          its citizens. Some of the important public facilities include
          infrastructure, sanitation, public transport, health care, water, etc.
          The government's main objective is to provide these public facilities
          to citizens and businesses to manage social and economic activities
          more smoothly.ANS :-Public facilities are services provided by the
          government to its citizens. Some of the important public facilities
          include infrastructure, sanitation, public transport, health care,
          water, etc. Read more.....
        </p> */}
        <div className="d-flex align-items-center pt-3">
          <div
            className="like d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => handleOnLike(questionId)}
          >
            {likes.includes(localStorage.getItem("userId")) ? (
              <i className="fa fa-solid fa-heart"></i>
            ) : (
              <i className="bi bi-heart"></i>
            )}{" "}
            {newLike === false
              ? likesCount + " " + "Like"
              : readerLike.length + " " + "Like"}
          </div>
          <div
            className="comment d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={toggleCommentBox}
          >
            <i className="bi bi-chat"></i>
            {newComment === false
              ? comments.length + " "
              : readerComment.length + " "}
            Comment
          </div>
        </div>
      </div>
      {commentBox && (
        <div className="comment-boxes">
          <div className="d-flex align-items-center">
            <img src={Img} alt="" className="comment-profile" />
            <input
              className="w-100 mx-3"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add your comment....."
            />
            <button
              className="blog-comment-btn"
              id={questionId}
              onClick={(e) => handleOnComment(e)}
            >
              Comment
            </button>
          </div>
        </div>
      )}
      {/* {commentBox && (
        <div
          className="comments-container"
          style={
            commentBox === true
              ? { marginBottom: "18px" }
              : { marginBottom: "0px" }
          }
        >
          {allComment}
        </div>
      )} */}
    </>
  );
}

export default QuestionCard;
