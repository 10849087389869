import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import SidebarContext from "../context/SidebarContext";
// import CartCard from "../Cards/CartCard";
// import img from "../Assets/ph_tag-light.png";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { baseUrl } from "../context/baseUrl";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CartPage from "./CartPage";

const Cart = () => {
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState({});
  const [toggleAdd, setToggleAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [selectedAdd, setSelectedAdd] = useState({
    address: "",
    pincode: "",
  });
  const [newAddress, setNewAddress] = useState({
    address: "",
    pincode: "",
  });
  useEffect(() => {
    getUserData();
    getCartData();
  }, []);
  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    const res = await axios({
      method: "get",
      url: baseUrl + `/user/getauser/${userId}`,
    });
    setUserData(res?.data?.data);
    setSelectedAdd(res?.data?.data?.shippingAddress[0]);
  };
  // console.log(userData, selectedAdd);
  const handleDelete = async (id) => {
    // setLoading(true)
    const userId = localStorage.getItem("userId");
    const res = await axios.patch(`${baseUrl}/user/address/delete/${userId}`, {
      addressId: id,
    });

    console.log(res);
    if (res?.status == 200) {
      alert("Address deleted Successfully.");
      getUserData();
      // setLoading(false)
    } else {
      alert("Address deleted Failed.");
      // setLoading(false)
    }
  };

  const handleAddAddress = async () => {
    if (newAddress?.address !== "" && newAddress?.pincode !== "") {
      const userId = localStorage.getItem("userId");
      const res = await axios.patch(
        `${baseUrl}/user/address/add/${userId}`,
        newAddress
      );

      console.log(res);
      if (res?.status == 200) {
        alert("Address Added Successfully.");
        // setLoading(false)
        setToggleAdd(false);
        setNewAddress({
          address: "",
          pincode: "",
        });
        getUserData();
      } else {
        alert("Address Added Failed.");
        // setLoading(false)
        setToggleAdd(false);
      }
    }
  };

  const getCartData = async () => {
    const token = localStorage.getItem("token");
    const res = await axios.get(`${baseUrl}/cart/getcart`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.status == 200) {
      setCart(res?.data?.data?.products);
      setTotalPrice(
        res?.data?.data?.products?.reduce(
          (accumulator, current) =>
            current?.product
              ? accumulator + current?.quantity * current?.product?.price
              : accumulator + 0,
          0
        )
      );
    }
  };

  const handleOrderPlace = async () => {
    if (!userData) return alert("First Login.");
    if(!selectedAdd) return alert("Please Select Address First.")
    if(!cart || cart.length==0) return alert("Cart Can not be Empty.")
    var cartFilter = cart?.filter((e) => e.product !== null);
    const payload ={
      name:userData?.name,
      email:userData?.email, 
      mobileno:userData?.phoneNumber, 
      products:cartFilter, 
      order_price:totalPrice, 
      coupon_applied:null, 
      shippingAddress:selectedAdd
    }
    console.log(payload)
    try {
      const saveOrder = await axios.post(`${baseUrl}/order/place`, payload, {headers:{
        Authorization:`Bearer ${localStorage.getItem("token")}`
      }})
      console.log(saveOrder)
      if(saveOrder?.status==200){
        try {
          const res = await axios.post(
            `${baseUrl}/order/phonepe`,
            {
              name: userData?.name,
              amount: totalPrice.toString(),
              number: userData?.phoneNumber,
              merchantUserId: saveOrder?.data?.data?._id,
            }
          );
          // console.log(res);
          window.location.href = res.data;
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      
    }

  };

  const handleupdateCard = () => {
    getCartData();
  };

  const handleSelect = (data)=>{
    setSelectedAdd(data)
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          <div className="cart-section">
            <div className="newcart">
              <br />
              <br />
              <br />
              <div className="cart-left">
                <h1 className="cart-heading">Your shopping cart</h1>
                <div className="addr">
                  <div>
                    <h1>ADDRESS</h1>
                    <p>
                      {selectedAdd?.address} {selectedAdd?.pincode}
                    </p>
                  </div>
                  <button onClick={handleOpen}>Change Address</button>
                </div>
                <br />
                <br />

                <hr />
                <div className="offer">
                  <div>
                    <h1>Available offers</h1>
                    <p>Flat 20% off on all order above 999</p>
                    <p style={{ color: "orange" }}>Show more</p>
                  </div>
                </div>
                <br />
                <br />

                <hr />

                <div className="cart-table">
                  <div className="table-heading">
                    <p style={{ width: "350px", marginLeft: "25px" }}>
                      PRODUCT
                    </p>
                    <p style={{ width: "182px", marginLeft: "-125px" }}>
                      QUANTITY
                    </p>
                    <p style={{ width: "50px" }}>TOTAL</p>
                  </div>
                  <hr />
                  <div>
                    {cart?.map((data, index) => (
                      <CartPage
                        product={data}
                        // setCart={setCart}
                        // setTotalPrice={setTotalPrice}
                        getCartData={handleupdateCard}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="cart-right">
                <div className="applycoupon">
                  <h1>Price details</h1>
                  <div className="coupon">
                    {/* <img src={img} /> */}
                    <p>Apply Coupons</p>
                    <button>Apply</button>
                  </div>
                </div>
                <br />
                <hr />
                <br />

                <p className="itemselected">Total items selected (2 items)</p>
                <div className="totalMRP">
                  <p>Total MRP</p>
                  <p>{totalPrice?.toFixed(2)}</p>
                </div>
                <div className="discount">
                  <p>Discount</p>
                  <p style={{ color: "#C02026" }}>-00</p>
                </div>
                <div className="totalMRP">
                  <p>Convenience fees</p>
                  <p>000</p>
                </div>
                <div className="billline"></div>
                <br />
                <div className="totalMRP">
                  <p>
                    <strong>Total Amount</strong>
                  </p>
                  <p>
                    <strong>{(totalPrice - discountPrice).toFixed(2)}</strong>
                  </p>
                </div>
                <button className="place-order-btn" onClick={handleOrderPlace}>Place Order</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            fontFamily: "Cinzel 'Cinzel', serif",
          }}
        >
          <Typography id="modal-title" variant="h6" component="div">
            Add and Delete Address
          </Typography>
          <br />
          {userData?.shippingAddress?.map((address) => (
            <div
              key={address._id}
              style={{
                marginBottom: "10px",
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
                p: 4,
                m: 4,
              }}
            >
              <div>
                <input
                  type="radio"
                  name="addressSelection"
                  checked={selectedAdd._id == address._id}
                  onChange={() => handleSelect(address)}
                />
                <Typography>{`Address: ${address.address}`}</Typography>
                <Typography>{`Pincode: ${address.pincode}`}</Typography>
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(address._id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
          <br />
          {toggleAdd ? (
            <div>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                value={newAddress?.address}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, address: e.target.value })
                }
                style={{ marginBottom: "10px" }}
              />
              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                value={newAddress?.pincode}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, pincode: e.target.value })
                }
                style={{ marginBottom: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAddress}
              >
                Submit
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setToggleAdd(true)}
              >
                Add Address
              </Button>
              <Button onClick={handleClose}>Close</Button>
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Cart;
