import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import TopWriter from "../components/answer/TopWriter";
import RecentWriter from "../components/answer/RecentWriter";
import SidebarContext from "../context/SidebarContext";
// import rightArrow from "../assets/right-arrow.svg";
import adImg from "../assets/ad-img.png";
import axios from "axios";
import ReaderCard from "../components/reader/ReaderCard";
import { useNavigate } from "react-router-dom";
import ReaderAddQuestion from "../components/AddQuestionModal/ReaderAddQuestion";
import { baseUrl } from "../context/baseUrl";
import ReaderCard2 from "../components/reader/ReaderCard2";

function ReaderClubPage() {
  const [IsOpen, setIsOpen] = useState(false);
  const side = useContext(SidebarContext);
  const { LeftSideBar, openLeftSidebar } = side;
  const [reader, setReader] = useState(null);
  const [qnaData, setQnaData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // async function getBlogs() {
  //   try {
  //     const res = await axios({
  //       method: "get",
  //       url: `${baseUrl}/content/getallcontent/likes?content_type=ReaderClub`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     setReader(res.data.data);
  //   } catch (e) {}
  // }
  // useEffect(() => {
  //   getBlogs();
  // }, []);

  async function getQnAData() {
    try {
      const res = await axios.get(`${baseUrl}/rnw?type=reader`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setQnaData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching Q&A data:", error);
    }
  }
  useEffect(() => {
    getQnAData();
  }, []);
  // console.log(qnaData);
  function handleSearch(event) {
    setSearchQuery(event.target.value);
  }

  const filteredQnAData = qnaData.filter((question) =>
    question?.question?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // var allReader = null;
  // if (reader !== null) {
  //   allReader = reader.map((element, index) => {
  //     // .likes.includes("userId")?liked:notliked
  //     return (
  //       <>
  //         <div key={index}>
  //           <ReaderCard
  //             // getBlog={getBlogs()}
  //             author={element.nickName}
  //             readerId={element._id}
  //             title={element.heading}
  //             description={element.description}
  //             date={element.createdAt}
  //             likes={element.likes}
  //             likesCount={element.likesCount}
  //             comments={element.commentArray}
  //             like={element}
  //           />
  //         </div>
  //       </>
  //     );
  //   });
  // }

  return (
    <>
      <Header />
    
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <div className="d-flex align-items-center path-box">
            <p className="path-name m-0">Reader Club</p>
            <img src={rightArrow} style={{ height: "20px"}} alt="" />
          </div> */}
          {/* <br/>
           */}
          <div
            className="d-flex justify-content-between path-box path-box2"
            style={{ paddingRight: "0" }}
          >
            {/* <div className="d-flex align-items-center">
              <p
                className="path-name m-0"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home /
              </p>
              <p
                className="path-name-bold m-0"
                onClick={() => {
                  navigate("/reader-club");
                }}
              >
                &nbsp;Reader
              </p>
            </div> */}
         
          </div>
         
          <div className="new-update-searchbar">
          <div className="search-container" style={{ padding: "auto" }}>
              <input
                type="text"
                placeholder="Search questions..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <i class="bi bi-search"></i>
            </div>
            <button className="add-question" onClick={() => setIsOpen(true)}>
              Add Question
            </button>
          </div>
          <br/>
          {/* {allReader} */}
          {filteredQnAData?.length > 0 &&
            filteredQnAData?.map((question) => (
              // <div key={question._id}>
              <ReaderCard2 data={question} getQnAData={getQnAData} />
              // </div>
            ))}
        </div>
     
        <div className="right-box">
          {/* <h2 className="right-title text-center">Reader review</h2>
          <div className="suggest-box">
            <TopWriter />
            <TopWriter />
            <TopWriter />
          </div>
          <div className="suggest-box">
            <ul
              className="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Top Reader
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Recent Reader
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <TopWriter />
                <TopWriter />
                <TopWriter />
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
                <RecentWriter />
              </div>
            </div>
          </div> */}
          <h2 className="recent-title text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
        
      </section>
      {IsOpen && (
        <ReaderAddQuestion setIsOpen={setIsOpen} getQnAData={getQnAData} />
      )}
    </>
  );
}

export default ReaderClubPage;
